import { fetchApi } from './api';

export const createSession = (email, password, action, store) =>
    fetchApi('/session', 'POST',
        {
            'email': email,
            'password': password
        },
        action, store, parseUserData);

export const deleteSession = (action, store) =>
    fetchApi('/session', 'DELETE', {}, action, store);


export const createAccount = (firstname, lastname, email, password, locale, action, store) =>
    fetchApi('/account', 'POST',
        {
            'firstname': firstname,
            'lastname': lastname,
            'email': email,
            'password': password,
            'locale': locale
        },
        action, store, parseUserData);

export const getAccount = (action, store) =>
    fetchApi('/account', 'GET', {}, action, store, parseUser);

export const updateAccount = (firstname, lastname, action, store) =>
    fetchApi('/account', 'PUT',
        {
            'firstname': firstname,
            'lastname': lastname
        },
        action, store, parseUser);

export const updateLocale = (locale, action, store) =>
    fetchApi('/account', 'PUT',
        {
            'locale': locale
        },
        action, store, parseUser);

export const updateEmail = (email, password, action, store) =>
    fetchApi('/account', 'PUT',
        {
            'email': email,
            'password': password
        },
        action, store, parseUser);

export const updatePassword = (oldPassword, newPassword, action, store) =>
    fetchApi('/account/password', 'PUT',
        {
            'old_password': oldPassword,
            'new_password': newPassword
        },
        action, store);


export const resetPassword = (email, action, store) =>
    fetchApi('/account/reset_password', 'POST',
        { 'email': email },
        action, store);


export const newPassword = (email, token, newPassword, action, store) =>
    fetchApi('/account/new_password', 'PUT',
        {
            'email': email,
            'token': token,
            'new_password': newPassword
        },
        action, store);

/*
|--------------------------------------------------------------------------
| Parser
|--------------------------------------------------------------------------
*/
export const parseUserData = (data) => {
    if (!data) {
        return;
    }

    return {
        token: data.token,
        user: parseUser(data.user),
    };
};

export const parseUser = (data) => {
    if (!data) {
        return;
    }

    return {
        id: parseInt(data.id, 10),
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        locale: data.locale
    };
};