import { FormControl, Input, InputLabel, MenuItem, Select } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = theme => ({
    formControl: {
        marginTop: `${theme.spacing.unit}px`,
        marginBottom: `${theme.spacing.unit}px`,
    },
    option: {
        '&:hover, &:focus, &:focus-within': {
            backgroundColor: `${theme.palette.action.hover} !important`,
        },
    },
    selectedOption: {
        fontWeight: 500,
        backgroundColor: 'inherit !important',
    },
});

class SimpleSelect extends Component {

    render() {
        const { classes, fullWidth, required, label, items, ...other } = this.props;

        return (
            <FormControl className={classes.formControl} fullWidth={fullWidth}>
                {label && <InputLabel htmlFor="select-simple" ref={ref => { this.InputLabelRef = ref; }}>
                    {label}
                </InputLabel>}
                <Select
                    input={<Input name="select" id="select-simple" />}
                    {...other}
                >
                    {(!required) &&
                        <MenuItem value="0"
                            classes={{
                                root: classes.option,
                                selected: classes.selectedOption
                            }}>
                            <em>none</em>
                        </MenuItem>
                    }
                    {items.map(item => {
                        return (
                            <MenuItem key={item.id} value={item.id}
                                classes={{
                                    root: classes.option,
                                    selected: classes.selectedOption
                                }}>
                                {item.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        );
    }
}

SimpleSelect.defaultProps = {
    fullWidth: false,
    required: false,
    label: null
};

SimpleSelect.propTypes = {
    classes: PropTypes.object.isRequired,
    fullWidth: PropTypes.bool,
    required: PropTypes.bool,
    label: PropTypes.string,
    items: PropTypes.array.isRequired,
};

export default withStyles(styles)(SimpleSelect);
