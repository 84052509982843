import { Typography } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Content from '../../../components/Common/Layout/Content';
import Paper from '../../../components/Common/Layout/Paper';

const styles = theme => ({
    title: {
        textAlign: 'center',
    },
    text: {
        textAlign: 'justify',
        padding: `${theme.spacing.unit * 2}px 0`,
    },
});

class About extends Component {

    render() {
        const { t, classes, theme } = this.props;

        return (
            <Content maxWidth={theme.size.maxWidthS}>
                <Paper>
                    <div className={classes.title}>
                        <Typography variant="h5">{t('about.title')}</Typography>
                    </div>
                    <div className={classes.text}>
                        <Typography>
                            Moi, c'est Véronique ! <span role="img" aria-label="smiley">👩</span><br />
                            <br />
                            Ingénieure en développement informatique de formation <span role="img" aria-label="informatique">👩‍💻</span>,
                            j'ai quitté le monde de l'entreprise il y a 5 ans.
                            Depuis je cherche à créer mon activité <span role="img" aria-label="work">🤔💼</span>,
                            dans quelque chose qui me passionne <span role="img" aria-label="love">❤️</span>, qui ait du sens,
                            qui soit concret et qui soit dans une démarche écoresponsable<span role="img" aria-label="nature">🌱🌿</span> !<br />
                            <br />
                            C'est également il y a 5 ans que j'ai commencé à coudre <span role="img" aria-label="sewing">✂️🧵🧷</span>,
                            et c'est dans la couture que j'ai pu trouver tout ce que je cherchais !
                            C'est donc tout naturellement que j'ai décidé, petit à petit,
                            de lancer mon activité dans ce domaine. Et j'espère sincèrement
                            que vous prendrez autant de plaisir à offrir - ou vous offrir -
                            mes créations, que j'en ai à les imaginer et les confectionner <span role="img" aria-label="smiley">😊😊😊</span>
                        </Typography>
                    </div>
                </Paper>
            </Content>
        );
    }
}

About.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(
    withTheme()(
        withTranslation()(About)
    )
);
