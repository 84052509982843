import { Typography } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Content from '../../../components/Common/Layout/Content';
import Paper from '../../../components/Common/Layout/Paper';
import LinkMailto from '../../../components/Common/Link/LinkMailto';

const styles = theme => ({
    div: {
        margin: `${theme.spacing.unit * 3}px 0`,
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing.unit * 2}px 0`,
        },
    },
    title: {
        marginBottom: `${theme.spacing.unit / 2}px`,
    },
    paragraph: {
        textAlign: 'justify',
        marginTop: `${theme.spacing.unit * 2}px`,
    },
});

class TermsAndConditions extends Component {
    render() {
        const { t, classes, theme } = this.props;

        return (
            <Content maxWidth={theme.size.maxWidthS}>
                <Paper>
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="h5">{t('terms.title')}</Typography>
                        <Typography variant="caption">{t('terms.subtitle')}</Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography component="p" className={classes.paragraph}>
                            Please read these Terms and Conditions ("Terms", " Terms and Conditions ") carefully before using https://smanager.lagunaworld.fr (the "Site") operated by Iridescent ("us", "we", or "our").
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            If you have any questions about these Terms, please contact us.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            1. Definitions
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The purpose of this clause is to define the various essential terms of the contract:<br />
                            <ul>
                                <li><i>User:</i> this term refers to any person who access or use the Site.</li>
                                <li><i>User content:</i> these are the data transmitted by the User within the Site.</li>
                                <li><i>Member:</i> the User becomes a member when he is identified on the Site.</li>
                                <li><i>Email and password:</i> this is all the information necessary to identify a User on the Site. The email and password allow the User to access services reserved for members of the Site. The password is confidential.</li>
                            </ul>
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            2. Object
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The Terms must be accepted by any User wishing to access the Site. They constitute the contract between the Site and the User. Access to the Site by the User means acceptance of these Terms.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            In case of non-acceptance of this Terms, the User must renounce access to the services offered by the Site.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            3. Legal Notice
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            <b>Owner:</b> Véronique Hoelt - 113 Route de Grenade 31700 Blagnac - FRANCE<br />
                            <b>Creator:</b> Véronique Hoelt / Iridescent<br />
                            <b>Publication Manager:</b> Véronique Hoelt - <LinkMailto mailto="veronique.hoelt@gmail.com">veronique.hoelt@gmail.com</LinkMailto><br />
                            The publication manager is a natural person.<br />
                            <b>Webmaster:</b> Véronique Hoelt - <LinkMailto mailto="veronique.hoelt@gmail.com">veronique.hoelt@gmail.com</LinkMailto><br />
                            <b>Data Protection Officer:</b> Véronique Hoelt - <LinkMailto mailto="veronique.hoelt@gmail.com">veronique.hoelt@gmail.com</LinkMailto><br />
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            <b>The Site is hosted by AMEN SASU</b><br />
                            <b>RCS Paris:</b> 421 527 797 000 11 - Company with share capital of 37 000 €<br />
                            <b>Postal address:</b> 12-14, Rond Point des Champs Elysées 75008 Paris - FRANCE<br />
                            <b>Phone:</b> 0811 88 77 66 from Monday to Friday from 9h to 19h (0,06 € / min + price of the call)<br />
                            <b>Email:</b> You can contact AMEN SASU via the "Contact Us" section of the Amen.fr website.
                        </Typography>
                    </div>


                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            4. Access to services
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The Site allows the User free access to the following services:<br />
                            <ul>
                                <li>Project management collaborative tool</li>
                            </ul>
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The Site is accessible for free to any user with Internet access. All costs incurred by the User to access the Site (hardware, software, Internet connection, etc.) are his responsibility.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Non-Member User does not have access to member services. For this, he must identify himself using his email and password.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The Site implements all the means at its disposal to ensure quality access to its services. The obligation being of means, the Site does not undertake to reach this result.
                            </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Any event due to force majeure causing a malfunction of the network or the server does not engage the responsibility of Iridescent.
                            </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Access to the services of the Site may be subject to interruption, suspension, modification without notice for reasons of maintenance or any other case at any time. The User agrees not to claim any compensation following the interruption, suspension or modification of a service.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The User can contact us using the contact form.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            5. Intellectual property
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The trademarks, logos, signs and other content of the Site are protected by the Intellectual Property Code.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The User must request the prior authorization of the Site for any reproduction, publication, copy of the various contents.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Any content posted by the User is his sole responsibility. The User agrees not to put on line content that may affect the interests of third parties. Any legal action brought by an aggrieved third party against the Site will be borne by the User.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The User Content may be at any time and for any reason deleted or modified by the Site. The User receives no justification and notification prior to the deletion or modification of the User Content.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            6. Personal data
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The information requested for registration on the Site is necessary and mandatory for the creation of the User's account. In particular, the email address that corresponds to the User's identifier and may be used by the Site for the administration, management and animation of the service.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The User assumes the communication of his email address to other Members. Sharing his email address allows the User to share content with other Members. His email address will then be visible to all Members with access to this same content.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Personal Information collected may be stored and processed in France, the User consents to this transfer of information outside of his country.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            7. Limitation of Liability
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The information given on the Site is for informational purposes, the Site reserves the right to a non-guarantee of the reliability of the information. The User assumes sole responsibility for the use of the information and contents of this Site.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The User assumes the risks related to the use of his email and password. The Site declines all responsibility.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The User is responsible for safeguarding the password that he use to access the Site and for any activities or actions under his password, whether his password is with our Site or a third-party service.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The User agree not to disclose his password to any third party. The User must notify us immediately upon becoming aware of any breach of security or unauthorized use of his account.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            We may terminate or suspend the User account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if the User breach the Terms.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Upon termination, the User right to use the Site will immediately cease.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Any use of the Site by the User directly or indirectly resulting in damages shall be subject to compensation for the Site.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            An optimal guarantee of the security and the confidentiality of the transmitted data is not assured by the Site. However, the Site is committed to implementing all necessary means to best guarantee the security and confidentiality of data.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            8. Links To Other Web Sites
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Our Site may contain links to third-party web sites or services that are not owned or controlled by us.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            We has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. The User further acknowledges and agrees that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            We strongly advise the User to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The User further acknowledges and agrees that we can not be held liable, directly or indirectly, for any damage or loss caused or presumed to have been caused by or in connection with the use or reliance on such content, product or service. service available on or through these websites or services.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            We strongly advise the User to read the terms and conditions and privacy policies of any third party website or service visited.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            9. Changes
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days notice prior to any new Terms taking effect. What constitutes a material change will be determined at our sole discretion.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            By continuing to access or use our Site after those revisions become effective, the User agrees to be bound by the revised terms. If the User does not agree to the new terms, he must stop using the Site.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            10. Duration
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The duration of this contract is indefinite. The contract has effect with respect to the User from the use of the Site.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            11. Governing Law
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            These Terms shall be governed and construed in accordance with the laws of France, without regard to its conflict of law provisions.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between the User and us regarding our Site, and supersede and replace any prior agreements we might have between the User and usregarding the Site.
                        </Typography>
                    </div>
                </Paper>
            </Content>
        );
    }
}

TermsAndConditions.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(
    withTheme()(
        withTranslation()(TermsAndConditions)
    )
);
