import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
// import i18n from 'i18next';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Content from '../../components/Common/Layout/Content';
import Paper from '../../components/Common/Layout/Paper';
// import InstagramFeed from '../../components/InstagramFeed';
// import EcoFriendly from '../../images/home/card/eco_friendly.png';
// import EcoResponsable from '../../images/home/card/eco_responsable.png';
// import Handmade from '../../images/home/card/handmade_with_love.png';
// import MadeInFrance from '../../images/home/card/made_in_occitanie.png';
import Sewing from '../../images/home/sewing.jpg';
// import Reviews from './reviews';

const styles = theme => ({
    firstDiv: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: `calc(100vh - ${theme.size.navbar})`,
    },
    introDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexGrow: 1,

        backgroundImage: `url(${Sewing})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',

        padding: `${theme.spacing.unit * 2}px`,
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing.unit * 5}px`,
        },

        '& #content': {
            maxWidth: theme.size.maxWidthS,
            width: '100%',
            margin: 'auto',
            padding: `${theme.spacing.unit * 4}px 0`,
            textAlign: 'center',
        },
        '& h2': {
            paddingBottom: `${theme.spacing.unit * 3}px`,

            color: 'black',
            lineHeight: '1.75em',
            fontSize: '2rem',
            [theme.breakpoints.up('md')]: {
                fontSize: '3rem',
            },
        },
        '& h4': {
            fontSize: '1.5rem',
            color: 'white',
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            width: '90%',
            margin: 'auto',
            padding: `${theme.spacing.unit * 2}px`,
            [theme.breakpoints.up('md')]: {
                marginTop: `${theme.spacing.unit * 8}px`,
            },
        },
    },
    cardsDiv: {
        textAlign: 'center',

        '& img': {
            width: '118px',
            margin: `${theme.spacing.unit * 4}px`,
            [theme.breakpoints.down('sm')]: {
                maxWidth: '118px',
                width: `calc(${100 / 3}vw - ${theme.spacing.unit * 2}px)`,
                margin: `${theme.spacing.unit}px`,
            },
        }
    },

    instaDiv: {
        backgroundColor: `${theme.palette.secondary.main}40`,
    },
    reviewsDiv: {
        backgroundColor: `${theme.palette.primary.main}40`,
    },

    maxWidthDiv: {
        width: `calc(100vw - ${theme.spacing.unit * 4}px)`,
        maxWidth: theme.size.maxWidthXL,
        margin: 'auto',
    },

    space: {
        height: `40px`,
        [theme.breakpoints.up('md')]: {
            height: `100px`,
        }
    },

    button: {
        fontSize: theme.typography.pxToRem(16),
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
        [theme.breakpoints.up('sm')]: {
            minWidth: '300px',
            margin: `${theme.spacing.unit * 3}px auto`,
            padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
        },
    },
});

class Home extends Component {

    render() {
        const { classes } = this.props;
        // const { t } = this.props;

        return (
            <Content fullWidth>
                <Paper fullWidth fullHeight withPadding={false}>

                    {/* INTRODUCTION */}
                    <div className={classNames(classes.firstDiv)}>
                        <div className={classNames(classes.introDiv)}>
                            <span id="content">
                                <Typography component="h2">
                                    <Trans i18nKey="home.description" />
                                </Typography>

                                {/* <Typography component="h4">
                                    {t('home.openThisAutomn')}
                                </Typography> */}

                            </span>
                        </div>
                        {/* <div className={classNames(classes.cardsDiv)}>
                            <img alt={t('home.card_1')} src={Handmade} />
                            <img alt={t('home.card_2')} src={MadeInFrance} />
                            <img alt={t('home.card_3')} src={i18n.language.includes("fr") ? EcoResponsable : EcoFriendly} />
                        </div> */}
                    </div>
                    {/* <div className={classes.space} /> */}

                    {/* SHOP LINKS */}
                    {/* <div className={classNames(classes.maxWidthDiv)}>
                        <ShopLinks />
                    </div>
                    <div className={classes.space} /> */}

                    {/* INSTAGRAM FEED */}
                    {/* <div className={classNames(classes.instaDiv)}>
                        <InstagramFeed />
                    </div>
                    <div className={classes.space} /> */}

                    {/* REVIEWS */}
                    {/* <div className={classNames(classes.maxWidthDiv, classes.reviewsDiv)}>
                        <Reviews />
                    </div>
                    <div className={classes.space} /> */}
                </Paper>
            </Content>
        );
    }
}


export default withStyles(styles)(
    withTranslation()(Home)
);
