import { withRouter } from "react-router";
import { useEffect } from "react";


const ScrollRestoration = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
};



export default withRouter(ScrollRestoration);
