import React, { Component } from 'react';
import Link from '..';

class NewTabLink extends Component {
    render() {
        const { ...other } = this.props;
        return (
            <Link target="_blank" rel="noopener noreferrer" {...other} />
        );
    }
}

export default NewTabLink;
