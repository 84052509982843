import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import FlagEN from '../../images/flag/en.png';
import FlagFR from '../../images/flag/fr.png';
import FacebookIcon from '../../images/social/facebook.svg';
import InstagramIcon from '../../images/social/instagram.svg';
import PinterestIcon from '../../images/social/pinterest.svg';
import ActionButton from '../Common/Action/ActionButton';
import NewTabLink from '../Common/Link/NewTabLink';

const styles = theme => ({
    footer: {
        maxWidth: theme.size.maxWidthM,
        margin: `${theme.spacing.unit * 2}px auto`,
        textAlign: 'center',
    },
    padding: {
        padding: `0 ${theme.spacing.unit * 1.5}px`,
    },
    onHover: {
        transition: 'transform .2s',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
    flag: {
        padding: `0 ${theme.spacing.unit / 2}px`,
        minWidth: 'inherit',
    }
})

class Footer extends Component {

    changeLanguage = lng => {
        if (lng !== this.props.i18n.language) {
            this.props.i18n.changeLanguage(lng);
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <Grid container spacing={8} alignItems="center" direction="column" className={classes.footer}>
                <Grid item>
                    {process.env.REACT_APP_FACEBOOK_ID && <NewTabLink className={classes.padding} href={"http://www.facebook.com/" + process.env.REACT_APP_FACEBOOK_ID}>
                        <img alt="facebook.com" src={FacebookIcon} height="40" width="40" className={classes.onHover} />
                    </NewTabLink>}
                    {process.env.REACT_APP_INSTAGRAM_ID && <NewTabLink className={classes.padding} href={"http://www.instagram.com/" + process.env.REACT_APP_INSTAGRAM_ID}>
                        <img alt="instagram.com" src={InstagramIcon} height="40" width="40" className={classes.onHover} />
                    </NewTabLink>}
                    {process.env.REACT_APP_PINTEREST_ID && <NewTabLink className={classes.padding} href={"http://www.pinterest.com/" + process.env.REACT_APP_PINTEREST_ID}>
                        <img alt="pinterest.com" src={PinterestIcon} height="40" width="40" className={classes.onHover} />
                    </NewTabLink>}
                </Grid>

                <Grid item>
                    <Typography variant="caption">
                        <span className={classes.padding}>
                            <ActionButton className={classes.flag} onClick={() => this.changeLanguage('fr')}>
                                <img alt="FR" src={FlagFR} height="24" width="24" className={classes.onHover} />
                            </ActionButton>
                            <ActionButton className={classes.flag} onClick={() => this.changeLanguage('en')}>
                                <img alt="EN" src={FlagEN} height="24" width="24" className={classes.onHover} />
                            </ActionButton>
                        </span>
                    </Typography>
                </Grid>

                <Grid item>
                    <Typography variant="caption">
                        Copyright &copy; {new Date().getFullYear()} {process.env.REACT_APP_WEBSITE_NAME}.
                    </Typography>
                </Grid>
            </Grid>
        );
    }
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(
    withTranslation()(Footer)
);
