import MuiPaper from '@material-ui/core/Paper';
import { withTheme } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Paper extends Component {
    render() {
        const { theme, width, maxWidth, fullWidth, fullHeight, fullFixedHeight, fullMaxHeight,
            withPadding, withOverflowX, dispatch, ...other } = this.props;

        var style = {};

        var withContentPadding = true;
        if ((maxWidth && !window.matchMedia(`(min-width: ${maxWidth}px)`).matches)
            || fullWidth || isWidthDown('sm', width)) {
            withContentPadding = false;
        }

        var height = 'calc(100vh - calc(' + theme.size.navbar;
        height += process.env.REACT_APP_UNDERCONSTRUCTION === 'true' ? '' : ' + ' + theme.size.submenu;
        height += withContentPadding ? ' + ' + (theme.spacing.unit * 2) + 'px' : '';
        height += '))';

        if (fullHeight || fullFixedHeight || fullMaxHeight) {
            style.display = 'flex';
            style.flexDirection = 'column';
        }

        if (fullHeight || fullFixedHeight) {
            style.minHeight = height;
        }
        if (fullFixedHeight || fullMaxHeight) {
            style.maxHeight = height;
        }

        style.backgroundColor = 'initial';
        style.boxShadow = 'initial';

        if (withOverflowX) {
            style.overflowX = 'auto';
        }

        return (
            <MuiPaper style={style} elevation={0} square {...other} />
        );
    }
}

Paper.defaultProps = {
    maxWidth: null,
    fullWidth: false,
    fullHeight: false,
    fullFixedHeight: false,
    fullMaxHeight: false,
    withPadding: true,
    withOverflowX: false,
};

Paper.propTypes = {
    theme: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    maxWidth: PropTypes.number,
    fullWidth: PropTypes.bool,

    fullHeight: PropTypes.bool,
    fullFixedHeight: PropTypes.bool,
    fullMaxHeight: PropTypes.bool,
    withPadding: PropTypes.bool,
    withOverflowX: PropTypes.bool,
};

export default withWidth()(
    withTheme()(Paper)
);
