import { push } from 'connected-react-router';
import * as contactApi from '../../services/contact';
import { actions as appTypes } from '../reducers/application';
import { actionCreators as notifActions } from './../reducers/notification';

const applicationMiddleware = store => next => action => {
    var result = null;

    /* BEFORE DISPATCH THE ACTION */
    switch (action.type) {
        /* NAVIGATION */
        case appTypes.goToHomeType:
        case appTypes.goToAuthAppType:
            store.dispatch(push('/'));
            break;
        case appTypes.goToErrorNotFoundType:
            store.dispatch(push('/error/404'));
            break;
        case appTypes.goToSignInType:
            store.dispatch(push('/signin'));
            break;
        case appTypes.goToSignUpType:
            store.dispatch(push('/signup'));
            break;
        case appTypes.goToAboutType:
            store.dispatch(push('/about'));
            break;

        /* CONTACT EMAIL */
        case appTypes.goToContactType:
            store.dispatch(push('/contact'));
            break;
        case appTypes.sendContactEmailSuccessType:
            store.dispatch(notifActions.showSuccess('contact.notif_email_sent'));
            break;

        default:
            break;
    }

    if (process.env.REACT_APP_TEST_DATA === 'true') {
        // Dispatch the action first
        result = next(action);

        // Then, return success
        switch (action.type) {
            case appTypes.sendContactEmailInitType:
                store.dispatch({ type: `${action.type}_SUCCESS`, data: {} });
                break;
            default:
                break;
        }
    } else {
        // Call the API 
        switch (action.type) {
            case appTypes.sendContactEmailInitType:
                contactApi.sendContactEmail(action.contact, action.type, store);
                break;
            default:
                break;
        }

        // Then, dispatch the action
        result = next(action);
    }

    /* AFTER DISPATCH THE ACTION */

    return result;
}

export default applicationMiddleware;