import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Button from '../../../components/Common/Action/Button';
import SimpleTextField from '../../../components/Common/Forms/SimpleTextField';
import AuthForm from '../../../components/Common/Layout/AuthForm';
import { actionCreators as appActions } from '../../../store/reducers/application';
import { actionCreators as cUserActions } from '../../../store/reducers/currentUser';
import { actionCreators as notifActions } from '../../../store/reducers/notification';

const styles = () => ({
    text: {
        textAlign: 'justify',
    },
});

class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            emailError: false,
            emailSent: false,
        };
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    sendEmail = () => {
        const email = this.state.email.trim();

        // validation
        this.setState({ emailError: email === '' });
        if (email === '') {
            return;
        }

        this.props.resetPassword(email);
    };

    render() {
        const { classes, goToSignIn, notifIsVisible, notifType } = this.props;
        const { email, emailError, emailSent } = this.state;
        const { t } = this.props;

        if (emailSent === true) {
            return (
                <AuthForm onEnter={goToSignIn}>
                    <Typography component="p" className={classes.text}>
                        {t('auth.forgotpassword.check_your_email')}
                    </Typography>
                    <Button fullWidth onClick={goToSignIn}>{t('auth.forgotpassword.returnToLogin_button')}</Button>
                </AuthForm>
            );
        } else {
            if (notifIsVisible && notifType === 'success') {
                // The email has been sent. Set state to show confirm message.
                this.setState({ emailSent: true });
                this.props.dismiss();
            }

            return (
                <AuthForm onEnter={this.sendEmail}>
                    <Typography component="p" className={classes.text}>
                        {t('auth.forgotpassword.title')}
                    </Typography>

                    <SimpleTextField fullWidth autoFocus
                        label={t('auth.common.email')}
                        id="email"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onChange={this.handleChange}
                        error={emailError} />

                    <br /><br />
                    <Button fullWidth onClick={this.sendEmail}>{t('auth.forgotpassword.sendEmail_button')}</Button>
                    <Button variant="outlined" fullWidth onClick={goToSignIn}>{t('auth.forgotpassword.returnToLogin_button')}</Button>
                </AuthForm>
            );
        }
    }
};

const mapStateToProps = state => {
    return {
        notifIsVisible: state.notification.isVisible,
        notifType: state.notification.type,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dismiss: () => {
            dispatch(notifActions.dismiss())
        },
        goToSignIn: () => {
            dispatch(appActions.goToSignIn())
        },
        resetPassword: (email) => {
            dispatch(cUserActions.resetPassword(email))
        },
    };
};

export default withStyles(styles)(
    withTranslation()(
        connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
    )
);
