import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { actionCreators as appActions } from '../../../../store/reducers/application';
import { actionCreators as navActions } from '../../../../store/reducers/navigation';
import Button from '../../Action/Button';
import OutlinedTextField from '../../Forms/OutlinedTextField';
import Paper from '../Paper';

const styles = theme => ({
    div: {
        textAlign: 'center',
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            minWidth: theme.size.maxWidthXS,
        },
    },
    title: {
        textTransform: 'uppercase',
    }
})

class SearchProduct extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: '',
            searchError: false,
        };
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onKeyPress = event => {
        if (event.key === 'Enter') {
            this.searchProduct();
            event.preventDefault();
        }
    };

    searchProduct = () => {
        const search = this.state.search.trim();

        // validation
        this.setState({ searchError: search === '' });
        if (search === '') {
            return;
        }

        this.props.searchProduct(search);
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;

        return (
            <Paper fullHeight>
                <div className={classes.div}>
                    <Typography variant="subtitle1" className={classes.title}>
                        {t(this.props.titleKey)}
                    </Typography>
                    <Typography component="p">
                        {t(this.props.subtitleKey)}
                    </Typography>
                    <br />
                    <br />
                    <br />
                    <OutlinedTextField fullWidth autoFocus
                        label={t('common.search_product')}
                        id="search"
                        name="search"
                        value={this.state.search}
                        onChange={this.handleChange}
                        error={this.state.searchError}
                        onKeyPress={this.onKeyPress}
                    />
                    <br />

                    {this.props.returnToShop && <Button variant="outlined" onClick={this.props.goToShop}>{t('common.goToShop_button')}</Button>}
                    {this.props.returnToHome && <Button variant="outlined" onClick={this.props.goToHome}>{t('common.goToHome_button')}</Button>}
                    <Button onClick={this.searchProduct}>{t('common.search_button')}</Button>
                    <br /><br /><br />
                </div>
            </Paper>
        );
    }
}

SearchProduct.defaultProps = {
    returnToHome: false,
    returnToShop: false,
};

SearchProduct.propTypes = {
    classes: PropTypes.object.isRequired,
    titleKey: PropTypes.string.isRequired,
    subtitleKey: PropTypes.string.isRequired,
    returnToHome: PropTypes.bool,
    returnToShop: PropTypes.bool,
};

const mapDispatchToProps = dispatch => {
    return {
        goToHome: () => {
            dispatch(appActions.goToHome())
        },
        goToShop: () => {
            dispatch(navActions.goToShop())
        },
        searchProduct: (search) => {
            dispatch(navActions.searchProduct(search))
        }
    };
};

export default withStyles(styles)(
    withTranslation()(
        connect(null, mapDispatchToProps)(SearchProduct)
    )
);
