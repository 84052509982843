import React, { Component } from 'react';
import Content from '../../../components/Common/Layout/Content';
import SearchProduct from '../../../components/Common/Layout/SearchProduct';

class NotFound extends Component {
    render() {
        return (
            <Content>
                <SearchProduct
                    titleKey="notfound.title"
                    subtitleKey="notfound.subtitle"
                    returnToHome
                />

            </Content>
        );
    }
}

export default NotFound;
