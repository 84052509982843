import { actionCreators as appActions } from './../reducers/application';
import { actionCreators as cUserActions } from './../reducers/currentUser';
import { actionCreators as notifActions } from './../reducers/notification';

function extractValidationErrorMessage(messages) {
    var missingFields = [];
    var translationKey = '';

    messages.forEach(msg => {
        if (msg.error === 'missing') {
            missingFields.push(msg.field);
        }
        else if (msg.error === 'invalid' && msg.field === 'email') {
            translationKey = 'error.invalid_email';
        }
        else if (msg.error === 'already_exists' && msg.field === 'email') {
            translationKey = 'error.email_already_exists';
        }
        else if (msg.error === 'does_not_exists' && msg.field === 'email') {
            translationKey = 'error.email_does_not_exists';
        }
    });

    if (missingFields.length > 0) {
        // 'The following fields are required: ' + missingFields.join();
        translationKey = (missingFields.length === 1) ? 'error.one_field_missing' : 'error.several_fields_missing';
    }

    return translationKey;
}

const apiErrorMiddleware = store => next => action => {
    // Dispatch the action
    const result = next(action);

    // Execute custom middleware handler after the action is dispatched
    if ((action.type).endsWith(`_ERROR`) && action.error) {
        var translationKey = '';
        switch (action.error.code) {
            case 'unauthorized':
                switch (action.error.message) {
                    case 'authentication_failed':
                        translationKey = 'error.incorrect_password';
                        break;
                    case 'invalid_reset_token':
                        translationKey = 'error.invalid_reset_token';
                        break;
                    default:
                        var token = store.getState().currentUser.accessToken;
                        if (token !== '') { /* invalid or expired token */
                            translationKey = 'error.expired_session';
                            store.dispatch(cUserActions.expireSession());
                            // Reauthentication dialog will be open
                        }
                        else { /* missing token */
                            store.dispatch(cUserActions.resetCurrentUser());
                        }
                        break;
                }
                break;

            case 'not_found':
            case 'conflict':
                translationKey = 'error.' + action.error.message;
                break;

            case 'validation_error':
                translationKey = extractValidationErrorMessage(action.error.message);
                break;

            case 'database_error':
                translationKey = 'error.service_unavailable';
                break;

            case 'mail_error':
                if (action.error.message === 'sending_email_failed' || action.error.message === 'email_could_not_be_sent') {
                    translationKey = 'error.sending_email_failed';
                }
                break;

            case 'model_not_found':
                store.dispatch(appActions.goToErrorNotFound());
                break;

            default:
                if (action.error.message === 'Network Error') {
                    translationKey = 'error.network_error';
                    break;

                } else {
                    translationKey = 'error.an_error_occured';
                    break;
                }
        }

        if (translationKey !== '') {
            store.dispatch(notifActions.showError(translationKey));
        }
    }
    return result;
}

export default apiErrorMiddleware;
