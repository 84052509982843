import MuiCheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = theme => ({
    root: {
        '&:hover': {
            color: theme.palette.secondary.main,
        }
    }
});

class Checkbox extends Component {
    render() {
        const { classes, ...other } = this.props;

        return (
            <MuiCheckbox classes={{ root: classes.root }} {...other} />
        );
    }
}

Checkbox.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Checkbox);
