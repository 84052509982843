import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import ReactMarkdown from 'markdown-to-jsx';
import React from 'react';

const styles = theme => ({
  paragraph: {
    '& pre, code': {
      display: 'inline',
    },
  },
  link: {
    color: theme.palette.primary.light,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'underline',
    },
  },
  blockquote: {
    '& p': {
      fontStyle: 'italic',
      color: theme.palette.text.secondary,
    },
  },
  list: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
  },

  tableHeader: {
    borderBottomWidth: '2px !important',
    '& span': {
      fontWeight: '500',
    },
  },
  tableCell: {
    borderCollapse: 'collapse !important',
    border: `1px solid ${theme.palette.divider}`,
    padding: `${theme.spacing.unit}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing.unit / 2}px`,
    },
  },
});

const options = {
  overrides: {
    h1: { component: props => <Typography variant="h5" {...props} /> },
    h2: { component: props => <Typography variant="h6" {...props} /> },
    h3: { component: props => <Typography variant="subtitle1" {...props} /> },
    h4: { component: props => <Typography variant="subtitle2" {...props} /> },

    p: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <span className={classes.paragraph}>
          <Typography paragraph {...props} />
        </span>
      )),
    },

    a: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <Typography component="a" inline {...props} className={classes.link} />
      )),
    },

    blockquote: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <blockquote className={classes.blockquote}>
          <Typography component="span" {...props} />
        </blockquote>
      )),
    },

    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.list}>
          <Typography component="span" {...props} />
        </li>
      )),
    },

    span: { component: props => <Typography component="span" {...props} /> },

    th: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <th className={classNames(classes.tableHeader, classes.tableCell)}>
          <Typography component="span" {...props} />
        </th>
      )),
    },
    td: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <td className={classes.tableCell}>
          <Typography component="span" {...props} />
        </td>
      )),
    },
  },
};

function Markdown(props) {
  return <ReactMarkdown options={options} style={{ overflowY: 'auto' }} {...props} />;
}

export default Markdown;
