import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Paper from '../../components/Common/Layout/Paper';
import SearchProduct from '../../components/Common/Layout/SearchProduct';
import ProductCard from './Product/card';

const styles = theme => ({
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: 'auto',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.size.maxWidthM,
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.size.maxWidthL,
        },
    },
    card: {
        margin: `${theme.spacing.unit * 2}px`,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: `calc(50% - ${theme.spacing.unit * 4}px)`,
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: `calc(${100 / 3}% - ${theme.spacing.unit * 4}px)`,
        },
    },
});

class ProductsList extends Component {

    render() {
        const { classes } = this.props;

        if (this.props.products.length === 0) {
            return (
                <SearchProduct
                    titleKey="emptylist.title"
                    subtitleKey="emptylist.subtitle"
                    returnToShop
                />
            );
        } else {
            return (
                <Paper className={classes.paper}>
                    {this.props.products.map(product =>
                        <ProductCard key={product.id} product={product} className={classes.card} />
                    )}
                </Paper>
            );
        }
    }
}

ProductsList.propTypes = {
    classes: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
};

export default withStyles(styles)(ProductsList);
