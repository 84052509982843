import { CssBaseline } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import ReactGA from 'react-ga';
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Footer from './components/Footer/underconstruction';
import LoaderOverlay from './components/LoaderOverlay';
import NavBar from './components/Menu/NavBar/underconstruction';
import Newsletter from './components/Newsletter';
import NotifSnackbar from './components/Notification/Snackbar';
import NotFound from './pages/Error/NotFound/underconstruction';
import Home from './pages/Home/underconstruction';
// import About from './pages/Static/About';
// import Contact from './pages/Static/Contact';

const styles = theme => ({
  root: {
    minHeight: `100vh`,
    display: 'flex',
    flexDirection: 'column',
  },
  footer: {
    backgroundColor: theme.palette.background.footer,
  },
  '@global': {
    // Selected Text
    '*::selection': {
      backgroundColor: `${theme.palette.action.selected}`, /* WebKit/Blink Browsers */
    },
    '*::-moz-selection': {
      backgroundColor: `${theme.palette.action.selected}`, /* Gecko Browsers */
    },

    // ScrollBar
    /* width */
    '*::-webkit-scrollbar': {
      width: theme.size.scroll,
    },
    /* Track */
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    /* Handle */
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    },
    /* Handle on hover */
    '*::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(0,0,0,.3)',
    },
  }
});

class AppUnderConstruction extends Component {

  componentDidMount() {
    // Add Google Analytics
    if (process.env.REACT_APP_GA_TRACKING_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID,
        {
          testMode: process.env.REACT_APP_GA_TEST_MODE === 'true',
          debug: process.env.REACT_APP_GA_DEBUG === 'true'
        });
      ReactGA.pageview('/');
    }

    // Check language
    if (this.props.userLocale !== i18n.language) {
      i18n.changeLanguage(this.props.userLocale);
    }
  }

  componentDidUpdate(prevProps) {
    // Check language
    if (this.props.userLocale !== null && prevProps.userLocale !== this.props.userLocale) {
      i18n.changeLanguage(this.props.userLocale);
    }
  }

  render() {
    const { classes, theme, width } = this.props;

    var headerStyle = {
      zIndex: '99',
      width: '100%',
    };
    var contentStyle = {
      flexGrow: 1,
    };
    if (isWidthUp('md', width)) {
      headerStyle.position = 'fixed';
      headerStyle.maxHeight = `${theme.size.navbar}`;
      headerStyle.minHeight = `${theme.size.navbar}`;
      contentStyle.paddingTop = `${theme.size.navbar}`;
    }

    return (
      <React.Fragment>
        <Suspense fallback={<LoaderOverlay loading />}>

          <CssBaseline />

          <LoaderOverlay loading={this.props.loading} />
          <NotifSnackbar />

          <div style={headerStyle}>
            <NavBar />
          </div>

          <div className={classes.root}>
            <div style={contentStyle}>
              <Switch>
                <Route exact path="/" component={Home} />

                <Route exact path="/error/404" component={NotFound} />

                {/* <Route exact path="/about" component={About} /> */}
                {/* <Route exact path="/contact" component={Contact} /> */}

                <Route component={NotFound} /> {/* If route does not exists */}
              </Switch>
            </div>

            <footer className={classes.footer}>
              <Newsletter />
              <Footer />
            </footer>
          </div>
        </Suspense>
      </React.Fragment>
    );
  }
}


AppUnderConstruction.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    loading: state.application.loading,
    userLocale: state.currentUser.locale,
  };
};

export default withStyles(styles)(
  withWidth()(
    withTheme()(
      connect(mapStateToProps, null)(AppUnderConstruction)
    )
  )
);
