import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Button from '../../../components/Common/Action/Button';
import SimpleTextField from '../../../components/Common/Forms/SimpleTextField';
import RouteLink from '../../../components/Common/Link/RouteLink';
import { actionCreators as appActions } from '../../../store/reducers/application';
import { actionCreators as cUserActions } from '../../../store/reducers/currentUser';

class ReauthenticateDialog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            email: '',
            password: '',
        };
    }

    componentDidUpdate() {
        if (!this.state.open && !this.props.isAuthenticated && this.props.userId !== null) {
            this.setState({ open: true });
        } else if (this.state.open && (this.props.isAuthenticated || this.props.userId === null)) {
            this.setState({ open: false });
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onKeyPress = event => {
        if (event.key === 'Enter') {
            this.signIn();
            event.preventDefault();
        }
    };

    signIn = () => {
        this.props.createSession(this.state.email, this.state.password)
    };

    returnToHomepage = () => {
        this.props.resetCurrentUser();
        this.props.goToHome();
    };

    render() {
        const { fullScreen } = this.props;
        const { t } = this.props;

        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.returnToHomepage()}
                fullScreen={fullScreen} fullWidth
                maxWidth="xs" scroll="body"
                disableBackdropClick>

                <DialogTitle>{t('auth.signin.expiredSession')}</DialogTitle>

                <DialogContent>
                    <form onKeyPress={this.onKeyPress}>
                        <SimpleTextField fullWidth autoFocus
                            label={t('auth.common.email')}
                            id="email"
                            name="email"
                            autoComplete="email"
                            value={this.state.email}
                            onChange={this.handleChange} />

                        <SimpleTextField fullWidth
                            label={t('auth.common.password')}
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            value={this.state.password}
                            onChange={this.handleChange} />

                        <RouteLink to="/forgot-password" variant="caption">{t('auth.signin.forgotPassword_link')}</RouteLink>
                        <br /><br />
                        <Button fullWidth onClick={this.signIn}>{t('auth.signin.button')}</Button>
                        <Button variant="outlined" fullWidth onClick={() => this.returnToHomepage()}>{t('common.goToHome_button')}</Button>
                    </form>
                </DialogContent>
            </Dialog>
        );
    }
}

ReauthenticateDialog.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.currentUser.authenticated,
        userId: state.currentUser.id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        createSession: (email, password) => {
            dispatch(cUserActions.createSession(email, password))
        },
        resetCurrentUser: () => {
            dispatch(cUserActions.resetCurrentUser())
        },
        goToHome: () => {
            dispatch(appActions.goToHome())
        },
    };
};

export default withMobileDialog()(
    withTranslation()(
        connect(mapStateToProps, mapDispatchToProps)(ReauthenticateDialog)
    )
);
