import { Fab, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ArrowUp from '@material-ui/icons/ArrowUpwardRounded';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
    fab: {
        backgroundColor: theme.palette.button.dark,
        color: theme.palette.button.light,
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    }
});

class ScrollToTopFab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollable: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollButtonVisibility);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollButtonVisibility);
    }

    scrollButtonVisibility = () => {
        // When the user scrolls down 20px from the top of the document, show the button
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            if (this.state.scrollable === false) {
                this.setState({ scrollable: true });
            }
        } else if (this.state.scrollable !== false) {
            this.setState({ scrollable: false });
        }
    }

    scrollToTop = () => {
        document.body.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
        document.documentElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    render() {
        const { scrollable } = this.state;
        const { t, i18n, tReady, classes, className, ...other } = this.props;

        if (scrollable) {
            return (
                <Tooltip title={t('action.scrollToTop')}>
                    <Fab className={classNames(classes.fab, className)} onClick={this.scrollToTop} {...other}>
                        <ArrowUp />
                    </Fab>
                </Tooltip>
            );
        } else {
            return '';
        }
    }
}

ScrollToTopFab.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(
    withTranslation()(ScrollToTopFab)
);
