import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Link from '..';

class LinkMailto extends Component {
    render() {
        const { t, i18n, tReady, mailto, ...other } = this.props;

        return (
            <Tooltip title={<Trans i18nKey="action.sendEmailTo">Send an email to {{ mailto }}</Trans>}>
                <Link href={'mailto:' + mailto} {...other} />
            </Tooltip>
        );
    }
}

LinkMailto.propTypes = {
    mailto: PropTypes.string.isRequired,
};

export default withTranslation()(LinkMailto);
