const axios = require('axios');
const i18n = require('i18next');

function dispatchError(error, action, store) {
    console.error(error);

    var type = `${action}_ERROR`;

    var returnError = {
        status: 500,
        code: 'error',
        message: error.message
    };
    if (error.response && error.response.data) {
        returnError.status = error.response.data.status;
        returnError.code = error.response.data.code;
        returnError.message = error.response.data.message;
    }

    // Dispatch action error
    store.dispatch({ type: `${type}`, error: returnError });
}

function dispatchSuccess(data, params, action, store, fParse) {
    if (fParse !== undefined && fParse !== null) {
        let result = null;
        if (data && Array.isArray(data)) {
            result = [];
            data.forEach(function (element) {
                result.push(fParse(element));
            });
        } else {
            result = fParse(data);
        }

        store.dispatch({ type: `${action}_SUCCESS`, data: result });
    } else {
        store.dispatch({ type: `${action}_SUCCESS`, data: params });
    }
};


export const fetchApi = (endpoint, method = 'get', params = {}, action, store, fParse = null) => {
    if (['get', 'post', 'put', 'delete'].includes(method.toLowerCase())) {
        axios({
            method: method.toLowerCase(),
            baseURL: process.env.REACT_APP_API_URL,
            url: endpoint,
            data: params,
            headers: {
                'Authorization': store.getState().currentUser.accessToken,
                'Accept-Language': i18n.default.language
            }
        })
            .then((response) => {
                dispatchSuccess(response.data, params, action, store, fParse);
            })
            .catch((error) => {
                dispatchError(error, action, store);
            });
    }
};