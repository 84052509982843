import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '..';

class RouteLink extends Component {
    renderLink = itemProps => <RouterLink to={this.props.to} {...itemProps} />;

    onClick(event) {
        event.stopPropagation();
        return false;
    }

    render() {
        const { to, ...other } = this.props;
        return (
            <Link component={this.renderLink} onClick={(event) => this.onClick(event)} {...other} />
        );
    }
}

RouteLink.propTypes = {
    to: PropTypes.string.isRequired,
};

export default RouteLink;
