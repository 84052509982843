import { IconButton, Snackbar, SnackbarContent as MuiSnackbarContent } from '@material-ui/core';
import { amber, blue, green, red } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircleRounded';
import CloseIcon from '@material-ui/icons/CloseRounded';
import ErrorIcon from '@material-ui/icons/ErrorRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';
import WarningIcon from '@material-ui/icons/WarningRounded';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { actionCreators as notifActions } from '../../../store/reducers/notification';

const variantIcon = {
    success: CheckCircleIcon,
    error: ErrorIcon,
    warning: WarningIcon,
    info: InfoIcon,
};

const variantBackgroundColor = {
    info: blue[600],
    success: green[600],
    error: red[700],
    warning: amber[700],
}

function SnackbarContent(props) {
    const { message, onClose, variant } = props;
    const Icon = variantIcon[variant];

    return (
        <MuiSnackbarContent
            style={{
                backgroundColor: variantBackgroundColor[variant],
                color: '#fff',
            }}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon style={{ fontSize: 20, opacity: 0.9, marginRight: '8px' }} />
                    {message}
                </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={onClose}
                >
                    <CloseIcon style={{ fontSize: 20 }} />
                </IconButton>,
            ]}
        />
    );
}

SnackbarContent.propTypes = {
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};


class NotifSnackbar extends Component {
    render() {
        const { isVisible, translationKey, type, dismiss } = this.props;
        const { t } = this.props;

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={isVisible}
                autoHideDuration={6000}
                onClose={dismiss}
            >
                <SnackbarContent
                    onClose={dismiss}
                    variant={type}
                    message={t(translationKey)}
                />
            </Snackbar>
        )
    }
}

const mapStateToProps = state => {
    return {
        isVisible: state.notification.isVisible,
        translationKey: state.notification.translationKey,
        type: state.notification.type,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dismiss: () => {
            dispatch(notifActions.dismiss())
        },
    };
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(NotifSnackbar)
);
