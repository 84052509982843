/* Initial state */
const initialState = {
    currency: 'eur',
    products: [],
};

/* Actions */
export const actions = {
    getProductsInitType: 'GET_PRODUCTS',
    getProductsSuccessType: 'GET_PRODUCTS_SUCCESS',
    getProductsErrorType: 'GET_PRODUCTS_ERROR',

    setProductsListType: 'SET_PRODUCTS_LIST',

    getProductByIdInitType: 'GET_PRODUCT_BY_ID',
    getProductByIdSuccessType: 'GET_PRODUCT_BY_ID_SUCCESS',
    getProductByIdErrorType: 'GET_PRODUCT_BY_ID_ERROR',
};

/* Action Creators */
export const actionCreators = {
    getProducts: () => ({ type: actions.getProductsInitType }),
    getProductById: (id) => ({ type: actions.getProductByIdInitType, id }),
};

/* Reducer */
export const reducer = (state, action) => {
    state = state || initialState;
    var list = [];

    switch (action.type) {
        case actions.getProductsSuccessType:
        case actions.setProductsListType:
            return Object.assign({}, state, { products: action.data });

        case actions.getProductByIdSuccessType:
            state.products.forEach((product) => {
                if (product.id === action.data.id) {
                    list.push(action.data);
                } else {
                    list.push(Object.assign({}, product));
                }
            });
            return Object.assign({}, state, { products: list });

        default:
            break;
    }

    return state;
};
