import { FormControlLabel, Typography, Tooltip } from '@material-ui/core';
import i18n from 'i18next';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Button from '../../../components/Common/Action/Button';
import Checkbox from '../../../components/Common/Forms/Checkbox';
import SimpleTextField from '../../../components/Common/Forms/SimpleTextField';
import AuthForm from '../../../components/Common/Layout/AuthForm';
import RouteLink from '../../../components/Common/Link/RouteLink';
import { actionCreators as appActions } from '../../../store/reducers/application';
import { actionCreators as cUserActions } from '../../../store/reducers/currentUser';
import { actionCreators as notifActions } from '../../../store/reducers/notification';

class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      pwdconfirm: '',
      keepLogin: false,

      firstnameError: false,
      lastnameError: false,
      emailError: false,
      passwordError: false,
      pwdconfirmError: false,
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  createAccount = () => {
    this.props.dismiss();

    const firstname = this.state.firstname.trim();
    const lastname = this.state.lastname.trim();
    const email = this.state.email.trim();
    const password = this.state.password.trim();
    const pwdconfirm = this.state.pwdconfirm.trim();

    // validation
    this.setState({ firstnameError: firstname === '' });
    this.setState({ lastnameError: lastname === '' });
    this.setState({ emailError: email === '' });
    this.setState({ passwordError: password === '' });
    this.setState({ pwdconfirmError: pwdconfirm === '' });

    if (firstname === '' || lastname === '' || email === '' || password === '' || pwdconfirm === '') {
      return;
    }

    this.setState({ pwdconfirmError: password !== pwdconfirm });
    if (password !== pwdconfirm) {
      return;
    }

    this.props.createAccount(firstname, lastname, email, password, this.state.keepLogin, i18n.language);
  }

  render() {
    const { goToSignIn } = this.props;
    const { t } = this.props;

    return (
      <AuthForm onEnter={this.createAccount}>
        <SimpleTextField fullWidth autoFocus
          label={t('auth.common.firstname')}
          id="firstname"
          name="firstname"
          value={this.state.firstname}
          onChange={this.handleChange}
          error={this.state.firstnameError} />

        <SimpleTextField fullWidth
          label={t('auth.common.lastname')}
          id="lastname"
          name="lastname"
          value={this.state.lastname}
          onChange={this.handleChange}
          error={this.state.lastnameError} />

        <SimpleTextField fullWidth
          label={t('auth.common.email')}
          id="email"
          name="email"
          autoComplete="email"
          value={this.state.email}
          onChange={this.handleChange}
          error={this.state.emailError} />

        <SimpleTextField fullWidth
          label={t('auth.common.password')}
          id="password"
          name="password"
          type="password"
          autoComplete="new-password"
          value={this.state.password}
          onChange={this.handleChange}
          error={this.state.passwordError} />

        <SimpleTextField fullWidth
          label={t('auth.common.passwordConfirmation')}
          id="pwdconfirm"
          name="pwdconfirm"
          type="password"
          autoComplete="new-password"
          value={this.state.pwdconfirm}
          onChange={this.handleChange}
          error={this.state.pwdconfirmError} />

        <br /><br />
        <Tooltip title={t('auth.common.keep_session_info')}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.keepLogin}
                onChange={() => this.setState({ keepLogin: !this.state.keepLogin })}
                value={this.state.keepLogin.toString()}
              />
            }
            label={t('auth.common.keep_session')}
          />
        </Tooltip>
        <br />
        <Button fullWidth onClick={this.createAccount}>{t('auth.signup.button')}</Button>
        <Typography variant="caption">
          <Trans i18nKey="auth.signup.privacy">
            By creating an account, you agree to our <RouteLink to="/privacy" variant="caption">Privacy Policy</RouteLink>.
          </Trans>
        </Typography>
        <br /><br />
        <Typography variant="caption">{t('auth.signup.goToSignIn_text')}</Typography>
        <Button variant="outlined" fullWidth onClick={goToSignIn}>{t('auth.signup.goToSignIn_button')}</Button>
      </AuthForm>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dismiss: () => {
      dispatch(notifActions.dismiss())
    },
    goToSignIn: () => {
      dispatch(appActions.goToSignIn())
    },
    createAccount: (firstname, lastname, email, password, keepLogin, locale) => {
      dispatch(cUserActions.createAccount(firstname, lastname, email, password, keepLogin, locale))
    },
  };
};

export default withTranslation()(
  connect(null, mapDispatchToProps)(Register)
);
