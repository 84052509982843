import { push } from 'connected-react-router';
import { actions as navTypes } from '../reducers/navigation';

const navigationMiddleware = store => next => action => {
    // Dispatch the action
    const result = next(action);

    // Execute custom middleware handler after the action is dispatched
    switch (action.type) {
        /* NAVIGATION ACTIONS */
        case navTypes.goToShopType:
            store.dispatch(push('/shop'));
            break;

        case navTypes.searchProductType:
            store.dispatch(push('/shop?search=' + action.search));
            break;

        case navTypes.goToMyCartType:
            store.dispatch(push('/cart'));
            break;

        case navTypes.goToMyProfilType:
            store.dispatch(push('/profil'));
            break;

        case navTypes.goToMyOrdersType:
            store.dispatch(push('/orders'));
            break;

        default:
            break;
    }
    return result;
}

export default navigationMiddleware;
