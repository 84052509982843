import { ClickAwayListener, FormControl, IconButton, Input, InputAdornment, Paper, Tab, Tabs } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/HomeRounded';
import SearchIcon from '@material-ui/icons/SearchRounded';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link as RouterLink } from 'react-router-dom';
import { actionCreators as navActions } from '../../../store/reducers/navigation';

const styles = theme => ({
    paperRoot: {
        backgroundColor: theme.palette.background.submenu,
        boxShadow: theme.menu.boxShadow,
        [theme.breakpoints.up('md')]: {
            position: 'fixed',
            width: '100%',
        },
    },
    contentDiv: {
        maxWidth: theme.size.maxWidthXL,
        width: '100%',
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            minHeight: theme.size.submenu,
            maxHeight: theme.size.submenu,
            display: 'flex',
            alignItems: 'center',
        },
    },
    tabs: {
        flexGrow: 1,
        minWidth: 'fit-content',
        '& a:hover, & a:focus, & a:focus-within': {
            fontWeight: 600,
            color: theme.palette.secondary.main,
            backgroundColor: 'initial',
        },
    },
    selected: {
        fontWeight: 600,
    },
    searchForm: {
        margin: `0 ${theme.spacing.unit / 2}px`,
        [theme.breakpoints.up('md')]: {
            margin: `0 ${theme.spacing.unit * 3}px`,
        },
        '& button': {
            padding: `${theme.spacing.unit / 2}px`,
        },
        '& input': {
            fontSize: '0.9rem',
            width: '200px',
        },
    },
});


class SubMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: '',
            searchError: false,
        };
    }

    onKeyPress = event => {
        if (event.key === 'Enter') {
            this.searchProduct();
            event.preventDefault();
        }
    };
    searchProduct = () => {
        const search = this.state.search.trim();

        // validation
        this.setState({ searchError: search === '' });
        if (search === '') {
            return;
        }

        this.setState({ search: '' });
        this.props.searchProduct(search);
    }

    render() {
        const { classes, location } = this.props;
        const { t } = this.props;

        var items = [
            { label: t('submenu.women'), path: "/shop/women" },
            { label: t('submenu.men'), path: "/shop/men" },
            { label: t('submenu.accessories'), path: "/shop/accessories" }
        ];

        var idx = items.findIndex(i => location.pathname.includes(i.path));
        if (idx < 0) {
            idx = location.pathname === "/shop" ? 0 : false;
        } else {
            idx++;
        }

        return (
            <Paper classes={{ root: classes.paperRoot }} square>
                <div className={classes.contentDiv}>
                    <Tabs value={idx} className={classes.tabs}
                        indicatorColor="primary" textColor="secondary">

                        <Tab key="tab_home" label={<HomeIcon />}
                            component={RouterLink} to="/shop"
                            classes={{ selected: classes.selected }} />

                        {items.map((item, index) => {
                            return (
                                <Tab key={'tab_' + index} label={item.label}
                                    component={RouterLink} to={item.path}
                                    classes={{ selected: classes.selected }} />);
                        })}
                    </Tabs>
                    <ClickAwayListener onClickAway={() => this.setState({ searchError: false })}>
                        <FormControl className={classes.searchForm}>
                            <Input
                                id="adornment-search"
                                placeholder={t('submenu.searchfield')}
                                value={this.state.search}
                                onChange={(event) => this.setState({ search: event.target.value })}
                                error={this.state.searchError}
                                onKeyPress={this.onKeyPress}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton aria-label="button search" onClick={this.searchProduct}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </ClickAwayListener>
                </div>
            </Paper>
        )
    }
}

SubMenu.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
    return {
        searchProduct: (search) => {
            dispatch(navActions.searchProduct(search))
        }
    };
};

export default withStyles(styles)(
    withRouter(
        connect(null, mapDispatchToProps)(
            withTranslation()(SubMenu)
        )
    )
);
