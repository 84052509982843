/*
|--------------------------------------------------------------------------
| Parser
|--------------------------------------------------------------------------
*/
export const parseVariation = (data) => {
    if (!data) {
        return;
    }

    return {
        id: data.id,
        attributes: data.attributes,
        image: data.image,
        price: data.price,
        currency: data.currency,
        quantity: data.quantity,
        infinite: data.infinite,
        available: data.available,
        packageDimensions: data.package_dimensions,
        productId: data.product_id,
    };
};
