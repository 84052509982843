import { fetchApi } from './api';
import * as variationApi from './variation';

export const getList = (action, store) =>
    fetchApi('/products', 'GET', {}, action, store, parseProduct);

export const getById = (id, action, store) =>
    fetchApi('/products/' + id, 'GET', {}, action, store, parseProduct);

/*
|--------------------------------------------------------------------------
| Parser
|--------------------------------------------------------------------------
*/
export const parseProduct = (data) => {
    if (!data) {
        return;
    }
    var url = data.url ? data.url.split('/') : [];

    var variations = [];
    var attributes = [];
    var values = {};
    for (let index = 0; index < data.variations.length; index++) {
        const variation = variationApi.parseVariation(data.variations[index]);
        variations.push(variation);
        for (let [key, value] of Object.entries(variation.attributes)) {
            if (!attributes.includes(key)) {
                attributes.push(key);
                values[key] = [];
            }
            if (!values[key].includes(value)) {
                values[key].push(value);
            }
        }
    }

    return {
        id: data.id,
        images: data.images,

        url: data.url,
        category: url.length >= 2 ? url[0] : 'uncategorized',
        slug: url.length >= 2 ? url[1] : url.length >= 1 ? url[0] : '',

        title: data.title,
        legend: data.legend,
        description: data.description,

        composition: data.composition,
        washingBoard: data.washing_board,
        dimension: data.dimension,
        weight: data.weight,

        relatedProductsIds: data.related_products_ids,

        packageDimensions: data.package_dimensions,

        variations: variations,
        variationsAttributes: attributes,
        variationsValues: values,
    };
};
