import { Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { isArray } from 'util';

const styles = theme => ({
    card: {
        transition: 'transform .2s',
        '&:hover': {
            transform: 'scale(1.02)',
            '& #media': {
                opacity: 0.8,
            },
        },
    },
    actionAreaRoot: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    actionAreaFocus: {
        backgroundColor: 'inherit',
    },
    media: {
        paddingTop: '75%',
        opacity: 1,
        transition: 'opacity .2s',
    },
    content: {
        width: '100%',
        flexGrow: 1,
    },
    actions_complete: {
        width: '100%',
        '& #price': {
            flexGrow: 1,
            textAlign: 'right',
            fontWeight: '500',
            fontSize: '1rem',
            padding: `0 ${theme.spacing.unit}px`,
        },
        '& #starting': {
            marginRight: `${theme.spacing.unit}px`,
        }
    },
    actions_summary: {
        '& #starting': {
            marginRight: `${theme.spacing.unit}px`,
        }
    },
});

class ProductCard extends Component {

    render() {
        const { t, i18n, classes, className, product, currency, mode } = this.props;

        var minPrice = null;
        var maxPrice = null;
        if (isArray(product.variations)) {
            for (let index = 0; index < product.variations.length; index++) {
                const variation = product.variations[index];

                if (variation.currency && variation.currency.toLowerCase() === currency) {

                    if (!minPrice || variation.price <= minPrice) {
                        minPrice = variation.price;
                    } else {
                        maxPrice = variation.price;
                    }
                }
            }
        }

        return (
            <Card className={classNames(classes.card, className)} key={product.id} square>
                <CardActionArea href={'/shop/' + product.category + '/' + product.slug}
                    classes={{
                        root: classes.actionAreaRoot,
                        focusHighlight: classes.actionAreaFocus
                    }}>

                    <div className={classes.content}>
                        <CardMedia id="media"
                            className={classes.media}
                            image={product.images && product.images.length > 0 ? product.images[0] : product.defaultImage}
                            title={product.title}
                        />
                        <CardContent>
                            {mode === 'complete' ?
                                <>
                                    <Typography gutterBottom variant="subtitle1" component="h3">
                                        {product.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {product.legend}
                                    </Typography>
                                </>
                                :
                                <Typography variant="subtitle2" component="h6">
                                    {product.title}
                                </Typography>
                            }
                        </CardContent>
                    </div>

                    <CardActions className={classes['actions_' + mode]}>
                        <Typography id="price">
                            {maxPrice !== null && minPrice !== maxPrice &&
                                <Typography id="starting" variant="caption" component="span" inline>{t('shop.price_starting_from')}</Typography>
                            }
                            {new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency }).format(minPrice)}
                        </Typography>
                    </CardActions>
                </CardActionArea>
            </Card>
        );
    }
}

ProductCard.defaultProps = {
    mode: 'complete',
};

ProductCard.propTypes = {
    classes: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    mode: PropTypes.oneOf(['complete', 'summary']),
};

const mapStateToProps = state => {
    return {
        currency: state.shop.currency,
    };
};

export default withStyles(styles)(
    withTranslation()(
        connect(mapStateToProps, null)(ProductCard)
    )
);
