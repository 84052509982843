import { MuiThemeProvider } from '@material-ui/core/styles';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from "react-router-dom";
import App from './App';
import './i18n';
import './index.css';
import theme from './muiTheme';
import ScrollRestoration from './scrollRestoration';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './store/configureStore';
import UnderConstruction from './AppUnderConstruction';

const store = configureStore(/* provide initial state if any */);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <ScrollRestoration>
                <MuiThemeProvider theme={theme}>
                    {process.env.REACT_APP_UNDERCONSTRUCTION === 'true' ?
                        <Route path="/" component={UnderConstruction} />
                        :
                        <Route path="/" component={App} />
                    }
                </MuiThemeProvider>
            </ScrollRestoration>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
