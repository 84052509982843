import { grey } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({

    palette: {
        primary: {
            // Marron
            light: '#F9EAE5',
            main: '#BAAFAB',
            dark: '#5D5755',
        },
        secondary: {
            // Terracotta
            light: '#FDF4F2',
            main: '#EEAC9E',
            dark: '#E2725B',
        },
        button: {
            dark: '#000',
            light: '#fff',
        },

        background: {
            paper: "#fff",
            default: "#fff",
            submenu: "#fdf9f8",
            footer: "#fbf1ee",

            badge: grey[600],
            label: '#e0e0e0',
        },

        action: {
            active: "rgba(93, 87, 85, 0.54)",
            hover: "rgba(93, 87, 85, 0.08)",
            hoverOpacity: 0.08,
            selected: "rgba(93, 87, 85, 0.14)",
            disabled: "rgba(93, 87, 85, 0.31)",
            disabledBackground: "rgba(93, 87, 85, 0.12)",
        },
    },

    typography: {
        fontFamily: [
            'Quicksand',
            '"Segoe UI Light"',
            'Roboto',
            'Verdana',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),

        h1: {
            fontSize: "3rem",
            fontWeight: 400,
        },
        h2: {
            fontSize: "2.5rem",
            fontWeight: 400,
        },
        h3: {
            fontSize: "2.25rem",
        },
        h4: {
            fontSize: "2rem",
        },
        h6: {
            fontWeight: 400,
        },
        subtitle1: {
            fontWeight: 500,
        },
    },

    size: {
        navbar: '90px',
        navbarSmall: '50px',
        submenu: '48px',
        switch: '40px',
        scroll: '10px',
        maxWidthXS: "550px",
        maxWidthS: "755px",
        maxWidthM: "960px",
        maxWidthL: "1024px",
        maxWidthXL: "1200px",
    },

    menu: {
        boxShadow: '0px 1px 2px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 1px -1px rgba(0,0,0,0.12)',
    },
});

export default theme;
