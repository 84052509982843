import { Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Markdown from '../../../components/Common/Markdown';

const styles = theme => ({
    cellRoot: {
        '&:nth-child(even)': {
            backgroundColor: `${theme.palette.secondary.light}40`,
        },
        '& th': {
            fontWeight: '600',
            padding: `${theme.spacing.unit}px`,
            [theme.breakpoints.up('sm')]: {
                width: '150px',
            },
        },
        '& p': {
            margin: `${theme.spacing.unit}px`,
            textAlign: 'justify',
        }
    },
});

class InformationsTab extends Component {

    render() {
        const { t, classes, product } = this.props;

        return (
            <Table>
                <TableBody>
                    <TableRow classes={{ root: classes.cellRoot }}>
                        <TableCell component="th">
                            {t('shop.composition')}
                        </TableCell>
                        <TableCell>
                            {product.composition &&
                                <Markdown>
                                    {product.composition}
                                </Markdown>
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow classes={{ root: classes.cellRoot }}>
                        <TableCell component="th">
                            {t('shop.washing_board')}
                        </TableCell>
                        <TableCell>
                            {product.washingBoard &&
                                <Markdown>
                                    {product.washingBoard}
                                </Markdown>
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow classes={{ root: classes.cellRoot }}>
                        <TableCell component="th">
                            {t('shop.dimension')}
                        </TableCell>
                        <TableCell>
                            {product.dimension &&
                                <Markdown>
                                    {product.dimension}
                                </Markdown>
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow classes={{ root: classes.cellRoot }}>
                        <TableCell component="th">
                            {t('shop.weight')}
                        </TableCell>
                        <TableCell>
                            {product.weight &&
                                <Typography>
                                    {product.weight} {t('shop.weight_unit')}
                                </Typography>
                            }
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }
}

InformationsTab.propTypes = {
    classes: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
};


export default withStyles(styles)(
    withTranslation()(InformationsTab)
);
