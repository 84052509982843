import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = () => ({
    mainLoader: {
        position: 'fixed',
        paddingTop: '15%',
        width: '100%',
        height: '100%',
        zIndex: 2000,
        backgroundColor: '#000000',
        opacity: 0.5,
        textAlign: 'center',
    },
});

class LoaderOverlay extends Component {

    render() {
        const { classes, className, loading } = this.props;

        if (loading) {
            return (
                <div className={classNames(classes.mainLoader, className)}>
                    <CircularProgress style={{ width: '100px', height: '100px' }} />
                </div>
            );
        } else {
            return '';
        }
    }
}

LoaderOverlay.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired
};


export default withStyles(styles)(LoaderOverlay);
