import { CircularProgress, ClickAwayListener, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { connect } from "react-redux";
import { actionCreators as notifActions } from '../../store/reducers/notification';
import Button from '../Common/Action/Button';
import OutlinedTextField from '../Common/Forms/OutlinedTextField';

const styles = theme => ({
    root: {
        maxWidth: theme.size.maxWidthM,
        margin: `${theme.spacing.unit * 2}px auto`,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    textDiv: {
        padding: `${theme.spacing.unit * 2}px`,
        '& p': {
            fontStyle: 'italic',
            color: theme.palette.primary.dark,
        }
    },
    formDiv: {
        padding: `${theme.spacing.unit * 2}px`,
        minWidth: 'max-content',
    },
    form: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },

        '& input': {
            backgroundColor: '#fff',
        },
        '& label': {
            zIndex: 1,
        },
    },
    result: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            width: '40px',
            height: '40px',
        }
    }
})

class Newsletter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newsemail: '',
            newsemailError: false,
            showResult: false,
        };
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onKeyPress = event => {
        if (event.key === 'Enter') {
            this.subscribe();
            event.preventDefault();
        }
    };

    validateEmail(email) {
        // eslint-disable-next-line
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    subscribe = (onValidated) => {
        const newsemail = this.state.newsemail.trim();

        // validation
        var error = newsemail === '' || !this.validateEmail(newsemail);
        this.setState({ newsemailError: error });
        if (error) { return; }

        this.setState({ showResult: true });
        onValidated({ EMAIL: newsemail });
    }

    showSuccess = () => {
        this.props.showSuccess('newsletter.success');
        this.setState({ showResult: false, newsemail: '' });
    }

    showError = (message) => {
        if (message.includes("already subscribed")) {
            this.props.showError('newsletter.error_already_subscribe');
        } else {
            this.props.showError('newsletter.error');
        }
        this.setState({ showResult: false });
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;

        const mailchimpUrl = process.env.REACT_APP_MAILCHIMP_NEWSLETTER_URL;
        return (!mailchimpUrl ? <></> :
            <div className={classes.root}>
                <div className={classes.textDiv}>
                    <Typography variant="h4" component="h4">{t('newsletter.title')}</Typography>
                    <Typography>{t('newsletter.text')}</Typography>
                </div>

                <div className={classes.formDiv}>
                    <ClickAwayListener onClickAway={() => this.setState({ newsemailError: false })}>
                        <MailchimpSubscribe url={mailchimpUrl}
                            render={({ subscribe, status, message }) => (
                                <form className={classes.form}>
                                    <OutlinedTextField
                                        label={t('newsletter.email')}
                                        id="newsemail"
                                        name="newsemail"
                                        value={this.state.newsemail}
                                        onChange={this.handleChange}
                                        error={this.state.newsemailError}
                                        onKeyPress={this.onKeyPress}
                                    />
                                    <Button onClick={() => this.subscribe(subscribe)}
                                        disabled={status === "sending"}>
                                        {t('newsletter.subscribe')}
                                    </Button>
                                    <span className={classes.result}>
                                        {this.state.showResult && status === "sending" &&
                                            <CircularProgress color="inherit" />}
                                        {this.state.showResult && status === "error" &&
                                            this.showError(message)}
                                        {this.state.showResult && status === "success" &&
                                            <CheckIcon /> && this.showSuccess()}
                                    </span>
                                </form>
                            )}
                        />
                    </ClickAwayListener>
                    <Typography variant="caption">{t('newsletter.notice')}</Typography>
                </div>
            </div>
        );
    }
}

Newsletter.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
    return {
        showSuccess: (translationKey) => {
            dispatch(notifActions.showSuccess(translationKey))
        },
        showError: (translationKey) => {
            dispatch(notifActions.showError(translationKey))
        },
    };
};


export default withStyles(styles)(
    withTranslation()(
        connect(null, mapDispatchToProps)(Newsletter)
    )
);
