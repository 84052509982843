import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Content from '../../../components/Common/Layout/Content';
import Paper from '../../../components/Common/Layout/Paper';

const styles = theme => ({
    root: {
        textAlign: 'center',
    },
    table: {
        padding: `${theme.spacing.unit * 4}px 0`,

        '& th': {
            fontWeight: 600,
            color: theme.palette.primary.dark,
            fontSize: '0.75rem',
        },
    },
});

class SizeGuide extends Component {

    createData(size, width, length, sleeves) {
        return { size, width, length, sleeves };
    }

    render() {
        const { t, classes, theme } = this.props;

        const menTshirt = [
            this.createData('XS', 44, 68, 62),
            this.createData('S', 46, 70, 63),
            this.createData('M', 50, 72, 64),
            this.createData('L', 54, 74, 65),
            this.createData('XL', 58, 76, 66),
            this.createData('XXL', 60, 78, 67)
        ];

        const womenTshirt = [
            this.createData('XS', 40, 60, 53),
            this.createData('S', 43, 62, 54),
            this.createData('M', 46, 64, 55),
            this.createData('L', 49, 66, 56),
            this.createData('XL', 52, 68, 57),
            this.createData('XXL', 55, 70, 58),
        ];


        return (
            <Content maxWidth={theme.size.maxWidthS}>
                <Paper className={classes.root}>
                    <Typography variant="h5">{t('sizeguide.title')}</Typography>

                    <div className={classes.table}>
                        <Typography variant="subtitle1">{t('sizeguide.men_tshirt')}</Typography>
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{t('sizeguide.size')}</TableCell>
                                    <TableCell align="center">{t('sizeguide.width')}</TableCell>
                                    <TableCell align="center">{t('sizeguide.length')}</TableCell>
                                    <TableCell align="center">{t('sizeguide.sleeves')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {menTshirt.map(row => (
                                    <TableRow key={row.size}>
                                        <TableCell component="th" scope="row" align="center">
                                            {row.size}
                                        </TableCell>
                                        <TableCell align="center">{row.width}</TableCell>
                                        <TableCell align="center">{row.length}</TableCell>
                                        <TableCell align="center">{row.sleeves}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>

                    <div className={classes.table}>
                        <Typography variant="subtitle1">{t('sizeguide.women_tshirt')}</Typography>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">{t('sizeguide.size')}</TableCell>
                                    <TableCell align="center">{t('sizeguide.width')}</TableCell>
                                    <TableCell align="center">{t('sizeguide.length')}</TableCell>
                                    <TableCell align="center">{t('sizeguide.sleeves')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {womenTshirt.map(row => (
                                    <TableRow key={row.size}>
                                        <TableCell component="th" scope="row" align="center">
                                            {row.size}
                                        </TableCell>
                                        <TableCell align="center">{row.width}</TableCell>
                                        <TableCell align="center">{row.length}</TableCell>
                                        <TableCell align="center">{row.sleeves}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            </Content>
        );
    }
}

SizeGuide.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(
    withTheme()(
        withTranslation()(SizeGuide)
    )
);
