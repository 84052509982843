import { Divider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ActionButton from '../../../components/Common/Action/ActionButton';
import Markdown from '../../../components/Common/Markdown';
import ProductCard from './card';

const styles = theme => ({
    description: {
        textAlign: 'justify',
        margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px ${theme.spacing.unit * 3}px`,
    },

    relatedProducts_title: {
        marginTop: '1.35em',
    },
    relatedProducts_main: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',

        '& #wrapper': {
            display: 'flex',
            flexDirection: 'row',
        },

        '& #container': {
            flexGrow: 1,
            display: 'flex',
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            overflowY: 'hidden',
        }
    },
    relatedProducts_item: {
        display: 'flex',
    },
    card: {
        margin: ` ${theme.spacing.unit / 2}px ${theme.spacing.unit}px`,
        width: '200px',
        maxWidth: `200px`,
        whiteSpace: 'pre-wrap',
    },
    button: {
        fontWeight: 'bold',
        fontSize: '20px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
});

class DescriptionTab extends Component {

    scroll(direction) {
        var elem = document.getElementById("container");
        let pos = elem.scrollLeft + (200 * direction);
        elem.scrollLeft = pos;
    }

    render() {
        const { t, classes, product, currency, products } = this.props;

        return (<>
            {product.description ?
                <Markdown className={classes.description}>
                    {product.description}
                </Markdown>
                :
                <Typography component="p" className={classes.description}>
                    {product.legend}
                </Typography>
            }

            {(product.description || product.legend) && (product.relatedProductsIds.length > 0) && <Divider />}

            {product.relatedProductsIds.length > 0 &&
                <div className={classes.relatedProducts_main}>
                    <Typography gutterBottom variant="subtitle1" className={classes.relatedProducts_title}>
                        {t('shop.related_products')}
                    </Typography>

                    <div id="wrapper">
                        <ActionButton className={classes.button}
                            onClick={() => this.scroll(-1)}>&#10094;</ActionButton>

                        <div id="container">
                            {product.relatedProductsIds.map(productId => {
                                var prod = products.find(p => p.id === productId);
                                if (prod) {
                                    return (
                                        <div key={productId} className={classes.relatedProducts_item}>
                                            <ProductCard
                                                product={prod} currency={currency}
                                                mode="summary" className={classes.card} />
                                        </div>
                                    );
                                } else {
                                    return '';
                                }
                            })}
                        </div>

                        <ActionButton className={classes.button}
                            onClick={() => this.scroll(1)}>&#10095;</ActionButton>
                    </div>
                </div>
            }
        </>);
    }
}

DescriptionTab.propTypes = {
    classes: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
};

export default withStyles(styles)(
    withTranslation()(DescriptionTab)
);
