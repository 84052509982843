import { actions as cart } from '../reducers/cart';
import { actionCreators as notifAction } from '../reducers/notification';

const shopMiddleware = store => next => action => {
    switch (action.type) {
        case cart.addProductInitType:
            // TODO: call endpoint to check availability and save user cart
            // cartapi.addProduct(action.variationId, action.type, store);
            break;

        case cart.removeProductInitType:
            // TODO: call endpoint to remove product from user cart
            // cartapi.removeProduct(action.variationId, action.type, store);
            break;

        default:
            break;
    }

    // Dispatch the action
    const result = next(action);

    switch (action.type) {
        case cart.addProductInitType:
            // TODO: Remove when endpoint was called
            store.dispatch({ type: cart.addProductSuccessType, data: { variationId: action.variationId } });
            break;
        case cart.removeProductInitType:
            // TODO: Remove when endpoint was called
            store.dispatch({ type: cart.removeProductSuccessType, data: { variationId: action.variationId } });
            break;

        case cart.addProductSuccessType:
            store.dispatch(notifAction.showSuccess('shop.added_to_cart'));
            break;

        case cart.removeProductSuccessType:
            store.dispatch(notifAction.showSuccess('shop.removed_from_cart'));
            break;

        default:
            break;
    }

    return result;
}

export default shopMiddleware;