import { FormControl, TextField } from '@material-ui/core/';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class OutlinedTextField extends Component {
    render() {
        const { fullWidth, ...other } = this.props;

        return (
            <FormControl variant="outlined" fullWidth={fullWidth}>
                <TextField variant="outlined" margin="dense"  {...other} />
            </FormControl>
        );
    }
}

OutlinedTextField.defaultProps = {
    fullWidth: false
};

OutlinedTextField.propTypes = {
    fullWidth: PropTypes.bool
};

export default OutlinedTextField;