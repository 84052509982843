import NoImageAvailable from '../../images/common/NoImageAvailable.png';
import NoImageAvailable_Accessory from '../../images/shop/NoImageAvailable_Accessory.png';
import NoImageAvailable_Man from '../../images/shop/NoImageAvailable_Man.png';
import NoImageAvailable_Woman from '../../images/shop/NoImageAvailable_Woman.png';
import * as productapi from '../../services/product';
import { actions as shop } from './../reducers/shop';

const checkProductsImages = (productsList, store) => {
    var products = [];
    productsList.forEach(product => {
        var checkedProduct = checkProduct(product, store);
        if (checkedProduct) {
            products.push(checkedProduct);
        }
    });
    // Update products list with default image
    store.dispatch({ type: shop.setProductsListType, data: products });
};

const checkProduct = (product, store) => {
    // Add default image
    var defaultImage = getImageFromCategory(product.category);
    var images = Array.from(product.images);

    if (product.images && product.images.length > 0) {
        // Check all images
        product.images.forEach((image, imageIdx) => {
            checkImageExists(image, (existsImage) => existsImageCallback(product.id, existsImage, imageIdx, store));
        });
    }

    return Object.assign({}, product, { defaultImage: defaultImage, images: images });
};

const getImageFromCategory = (category) => {
    switch (category) {
        case 'accessories': return NoImageAvailable_Accessory;
        case 'women': return NoImageAvailable_Woman;
        case 'men': return NoImageAvailable_Man;
        default: return NoImageAvailable;
    }
};

const checkImageExists = (imageUrl, callBack) => {
    var imageData = new Image();
    imageData.onload = function () { callBack(true) };
    imageData.onerror = function () { callBack(false) };
    imageData.src = imageUrl;
};

const existsImageCallback = (productId, existsImage, imageIdx, store) => {
    if (!existsImage) { // image not exist => remove image from array
        var productIdx = store.getState().shop.products.findIndex((p) => p.id === productId);
        if (productIdx > -1) {
            var products = Array.from(store.getState().shop.products);
            var images = Array.from(products[productIdx].images);
            images.splice(imageIdx, 1);
            products[productIdx] = Object.assign({}, products[productIdx], { images: images });

            // Update products list without bad image
            store.dispatch({ type: shop.setProductsListType, data: products });
        }
    }
};

const shopMiddleware = store => next => action => {
    switch (action.type) {
        case shop.getProductsInitType:
            productapi.getList(action.type, store);
            break;

        case shop.getProductByIdInitType:
            productapi.getById(action.id, action.type, store);
            break;

        case shop.getProductByIdSuccessType:
            var checkedProduct = checkProduct(action.data, store);
            if (checkedProduct) {
                action.data = checkedProduct;
            }
            break;

        default:
            break;
    }

    // Dispatch the action
    const result = next(action);

    switch (action.type) {
        case shop.getProductsSuccessType:
            checkProductsImages(action.data, store);
            break;

        default:
            break;
    }

    return result;
}

export default shopMiddleware;