import { Link as MuiLink } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = theme => ({
    root: {
        color: theme.palette.secondary.dark,
        '&:hover': {
            textDecoration: 'underline',
        }
    },
});

class Link extends Component {
    render() {
        const { classes, ...other } = this.props;
        return (
            <MuiLink variant="body1" classes={{ root: classes.root }} {...other} />
        );
    }
}

Link.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Link);
