import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Button from '../../../components/Common/Action/Button';
import SimpleTextField from '../../../components/Common/Forms/SimpleTextField';
import AuthForm from '../../../components/Common/Layout/AuthForm';
import { actionCreators as cUserActions } from '../../../store/reducers/currentUser';

const styles = () => ({
    text: {
        textAlign: 'center',
    },
});

class ResetPassword extends Component {

    constructor(props) {
        super(props);

        const params = queryString.parse(props.location.search);
        this.state = {
            email: params.email,
            token: params.token,
            password: '',
            pwdconfirm: '',
            passwordError: false,
            pwdconfirmError: false,
        };
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    changePassword = () => {
        const password = this.state.password.trim();
        const pwdconfirm = this.state.pwdconfirm.trim();

        // validation
        this.setState({ passwordError: password === '' });
        this.setState({ pwdconfirmError: pwdconfirm === '' });
        if (this.state.email === '' || password === '' || pwdconfirm === '') {
            return;
        }
        this.setState({ pwdconfirmError: password !== pwdconfirm });
        if (password !== pwdconfirm) {
            return;
        }

        this.props.newPassword(this.state.email, this.state.token, password);
    };

    render() {
        const { classes } = this.props;
        const { password, passwordError, pwdconfirm, pwdconfirmError } = this.state;
        const { t } = this.props;

        return (
            <AuthForm onEnter={this.changePassword}>
                <Typography component="p" className={classes.text}>
                    {t('auth.resetpassword.title')}
                </Typography>

                <SimpleTextField fullWidth autoFocus
                    label={t('auth.common.password')}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={this.handleChange}
                    error={passwordError} />

                <SimpleTextField fullWidth
                    label={t('auth.common.passwordConfirmation')}
                    id="pwdconfirm"
                    name="pwdconfirm"
                    type="password"
                    value={pwdconfirm}
                    onChange={this.handleChange}
                    error={pwdconfirmError} />

                <br /><br />
                <Button fullWidth onClick={this.changePassword}>{t('auth.resetpassword.button')}</Button>
            </AuthForm>
        );
    }
}

ResetPassword.propTypes = {
    location: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
    return {
        newPassword: (email, token, newPassword) => {
            dispatch(cUserActions.newPassword(email, token, newPassword))
        },
    };
};

export default withStyles(styles)(
    withRouter(
        withTranslation()(
            connect(null, mapDispatchToProps)(ResetPassword)
        )
    )
);
