import * as api from '../../services/user';
import { actionCreators as appActions } from '../reducers/application';
import { actions as userTypes } from '../reducers/currentUser';
import { actionCreators as notifActions } from '../reducers/notification';

const currentUserMiddleware = store => next => action => {
    var result = null;

    /* BEFORE DISPATCH THE ACTION */

    if (process.env.REACT_APP_TEST_DATA === 'true') {
        // Dispatch the action first
        result = next(action);

        // Then, return test data
        switch (action.type) {
            case userTypes.createSessionInitType:
                store.dispatch({
                    type: `${action.type}_SUCCESS`, data: {
                        token: 'token',
                        user: { id: 1, email: action.email, firstname: 'User', lastname: 'Test' },
                    }
                });
                break;

            case userTypes.deleteSessionInitType:
                store.dispatch({ type: `${action.type}_SUCCESS`, data: {} });
                break;

            case userTypes.createAccountInitType:
                store.dispatch({
                    type: `${action.type}_SUCCESS`, data: {
                        token: 'token',
                        user: { id: 1, email: action.email, firstname: action.firstname, lastname: action.lastname },
                    }
                });
                break;

            case userTypes.getAccountInitType:
                store.dispatch({
                    type: `${action.type}_SUCCESS`, data: {
                        token: 'token',
                        user: { id: 1, email: action.email, firstname: action.firstname, lastname: action.lastname },
                    }
                });
                break;

            case userTypes.updateAccountInitType:
                store.dispatch({ type: `${action.type}_SUCCESS`, data: { firstname: action.firstname, lastname: action.lastname } });
                break;

            case userTypes.updateEmailInitType:
                store.dispatch({ type: `${action.type}_SUCCESS`, data: { email: action.email } });
                break;

            case userTypes.updatePasswordInitType:
                store.dispatch({ type: `${action.type}_SUCCESS`, data: {} });
                break;

            case userTypes.resetPasswordInitType:
                store.dispatch({ type: `${action.type}_SUCCESS`, data: {} });
                break;

            case userTypes.newPasswordInitType:
                store.dispatch({ type: `${action.type}_SUCCESS`, data: {} });
                break;
            default:
                break;
        }
    } else {
        // Call the API
        switch (action.type) {
            case userTypes.createSessionInitType:
                api.createSession(action.email, action.password, action.type, store);
                break;
            case userTypes.deleteSessionInitType:
                api.deleteSession(action.type, store);
                break;
            case userTypes.createAccountInitType:
                api.createAccount(action.firstname, action.lastname, action.email, action.password, action.locale, action.type, store);
                break;
            case userTypes.getAccountInitType:
                api.getAccount(action.type, store);
                break;
            case userTypes.updateAccountInitType:
                api.updateAccount(action.firstname, action.lastname, action.type, store);
                break;
            case userTypes.updateLocaleInitType:
                api.updateLocale(action.locale, action.type, store);
                break;
            case userTypes.updateEmailInitType:
                api.updateEmail(action.email, action.password, action.type, store);
                break;
            case userTypes.updatePasswordInitType:
                api.updatePassword(action.oldPassword, action.newPassword, action.type, store);
                break;
            case userTypes.resetPasswordInitType:
                api.resetPassword(action.email, action.type, store);
                break;
            case userTypes.newPasswordInitType:
                api.newPassword(action.email, action.token, action.newPassword, action.type, store);
                break;
            default:
                break;
        }

        // Then, dispatch the action
        result = next(action);
    }

    /* AFTER DISPATCH THE ACTION */
    switch (action.type) {
        case userTypes.deleteSessionSuccessType:
        case userTypes.deleteSessionErrorType:
            // the user has disconnected, return to the Homepage
            store.dispatch(appActions.goToHome());
            break;

        case userTypes.newPasswordSuccessType:
            store.dispatch(appActions.goToSignIn());
            break;

        case userTypes.resetPasswordSuccessType:
            // Success message is in page ForgotPassword
            store.dispatch(notifActions.showSuccess(""));
            break;

        default:
            break;
    }

    return result;
}

export default currentUserMiddleware;