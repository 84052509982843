import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import NoImageAvailable from '../../../../images/common/NoImageAvailable.png';
import ImageZoomDialog from './dialog';

const styles = theme => ({
    root: {
        boxSizing: 'border-box',
        '& div': {
            boxSizing: 'border-box',
        },
    },

    /* Position the image container (needed to position the left and right arrows) */
    container: {
        position: 'relative',
    },

    /* Hide the images by default */
    inactiveSlide: {
        display: 'none',
    },
    /* Show the image */
    activeSlide: {
        display: 'block',
        overflow: 'hidden',
    },

    /* Image style to keep 1:1 Aspect Ratio */
    image: {
        width: '100%',
        paddingTop: '100%', /* 1:1 Aspect Ratio */
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
    },

    zoomIn: {
        transformOrigin: 'center',
        transition: 'transform .2s',
        '&:hover, &:focus, &:focus-within': {
            transform: 'scale(1.02)',
        },
    },

    /* Add a pointer when hovering over the thumbnail images */
    cursor: {
        cursor: 'pointer',
    },

    /* Next & previous buttons */
    button: {
        cursor: 'pointer',
        position: 'absolute',
        top: '50%',
        marginTop: '-40px',
        minWidth: '50px',
        minHeight: '50px',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '20px',
        borderRadius: '0 3px 3px 0',
        userSelect: 'none',
        '-webkit-user-select': 'none',

        /* Background color with transparency */
        backgroundColor: 'rgba(0, 0, 0, 0.25)',

        /* On hover, add a black background color with a little bit see-through */
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },

        /* For small screen: reduce the size and add a background color */
        [theme.breakpoints.down('sm')]: {
            marginTop: '-30px',
            minWidth: '40px',
            minHeight: '40px',
            fontSize: '16px',
            backgroundColor: 'rgb(0, 0, 0)',
        },
    },

    /* Position the "next button" to the right */
    next: {
        right: 0,
        borderRadius: '3px 0 0 3px',
    },

    row: {
        '&::after': {
            content: "''",
            display: 'table',
            clear: 'both',
        },
    },

    /* Six columns side by side */
    column: {
        float: 'left',
        width: 'calc(16.66% - 4px)',
        margin: '4px 2px 0 2px',
    },

    /* Add a transparency effect for thumnbail images */
    thumb: {
        opacity: 0.6,
        '&:hover': {
            opacity: 1,
        },
    },
    activeThumb: {
        opacity: 1,
    }
});

class Slideshow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            slideIndex: 0,
            images: [],
            zoomImage: false,

            lastTouch: 0,
            delta: 0,
        };
    }

    componentDidMount() {
        this.updateImages();
    }
    componentDidUpdate(prevProps) {
        // Check if images list has changed
        if (JSON.stringify(prevProps.images) !== JSON.stringify(this.props.images)) {
            // Update images
            this.updateImages();
        }
    }
    updateImages() {
        var images;
        if (this.props.images.length > 0) {
            images = Array.from(this.props.images);
        } else {
            // Add default image
            var defaultImage = this.props.defaultImage ? this.props.defaultImage : NoImageAvailable;
            images = [defaultImage];
        }

        this.setState({ images: images },
            () => {
                if (this.props.checkImages) {
                    // Check all images
                    this.existsImageCallback.bind(this);

                    this.state.images.forEach((image, index) => {
                        this.checkImageExists(image, (existsImage) => this.existsImageCallback(existsImage, index));
                    });
                }
            }
        );
    }

    // Next/previous controls and Thumbnail image controls
    changeIndex(index) {
        if (index >= 0) {
            if (index > (this.state.images.length - 1)) {
                index = 0;
            }
        } else {
            index = this.state.images.length - 1;
        }
        this.setState({ slideIndex: index });
    }

    checkImageExists(imageUrl, callBack) {
        var imageData = new Image();
        imageData.onload = function () {
            callBack(true);
        };
        imageData.onerror = function () {
            callBack(false);
        };
        imageData.src = imageUrl;
    }

    existsImageCallback(existsImage, index) {
        if (!existsImage) { // image not exist
            var array = Array.from(this.state.images);
            array.splice(index, 1);
            if (this.props.replaceImage || array.length <= 0) {
                array.splice(index, 0, this.props.defaultImage ? this.props.defaultImage : NoImageAvailable);
            }
            this.setState({ images: array });
        }
    }

    handleTouchStart = e => {
        this.setState({ lastTouch: e.nativeEvent.touches[0].clientX });
    };
    handleTouchMove = e => {
        const delta = this.state.lastTouch - e.nativeEvent.touches[0].clientX;
        this.setState({ lastTouch: e.nativeEvent.touches[0].clientX, delta: delta });
    };
    handleTouchEnd = () => {
        if (this.state.delta > 0) {
            this.changeIndex(this.state.slideIndex + 1);
        } else if (this.state.delta < 0) {
            this.changeIndex(this.state.slideIndex - 1);
        }
        this.setState({ lastTouch: 0, delta: 0 });
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classNames(classes.root, classes.container)}> {/* Container for the image gallery */}

                {this.state.zoomImage &&
                    <ImageZoomDialog isOpen
                        onClose={() => this.setState({ zoomImage: false })}
                        onPrevious={() => this.changeIndex(this.state.slideIndex - 1)}
                        onNext={() => this.changeIndex(this.state.slideIndex + 1)}
                        image={this.state.images[this.state.slideIndex]} />
                }
                {/* Full-width images with number text */}
                {this.state.images.map((image, index) => {
                    return (
                        <div key={"slide_" + index}
                            className={this.state.slideIndex === index ? classes.activeSlide : classes.inactiveSlide}>

                            <div className={classNames(classes.image, classes.cursor, classes.zoomIn)}
                                style={{
                                    backgroundImage: `url(${image})`,
                                    transform: `translateX(${this.state.delta * -1}px)`,
                                }}
                                onClick={() => this.setState({ zoomImage: true })}
                                onTouchStart={this.handleTouchStart}
                                onTouchMove={this.handleTouchMove}
                                onTouchEnd={this.handleTouchEnd}
                            />
                        </div>
                    );
                })}

                {this.state.images.length > 1 ? <>
                    {/* Next and previous buttons */}
                    <Button className={classNames(classes.button, classes.prev)}
                        onClick={() => this.changeIndex(this.state.slideIndex - 1)}>&#10094;</Button>
                    <Button className={classNames(classes.button, classes.next)}
                        onClick={() => this.changeIndex(this.state.slideIndex + 1)}>&#10095;</Button>

                    {/* Thumbnail images */}
                    <div className={classes.row}>
                        {this.state.images.map((image, index) => {
                            return (
                                <div key={"thumbnail_" + index} className={classes.column}>
                                    <div className={classNames(classes.image, classes.thumb, classes.cursor, this.state.slideIndex === index ? classes.activeThumb : '')}
                                        style={{ backgroundImage: `url(${image})` }}
                                        onClick={() => this.changeIndex(index)} />
                                </div>
                            );
                        })}
                    </div>
                </> : ''}
            </div>
        );
    }
}

Slideshow.defaultProps = {
    checkImages: false,
    replaceImage: false,
};

Slideshow.propTypes = {
    classes: PropTypes.object.isRequired,
    images: PropTypes.array.isRequired,
    defaultImage: PropTypes.string,
    checkImages: PropTypes.bool,
    replaceImage: PropTypes.bool,
};


export default withStyles(styles)(Slideshow);
