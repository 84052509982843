import { Avatar } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/LockOutlined';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from "react-redux";
import { actionCreators as appActions } from '../../../../store/reducers/application';
import Content from '../Content';
import Paper from '../Paper';

const styles = theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
    '& button': {
      margin: `${theme.spacing.unit}px auto`,
      [theme.breakpoints.down('xs')]: {
        margin: `${theme.spacing.unit / 2}px auto`,
      },
    },
  },
});

class AuthForm extends Component {

  onKeyPress = event => {
    if (event.key === 'Enter') {
      this.props.onEnter();
      event.preventDefault();
    }
  };

  render() {
    const { classes, theme, onEnter, isAuthenticated, goToAuthApp, ...other } = this.props;

    if (this.props.isAuthenticated) {
      // If user is authenticated, redirect to Homepage
      this.props.goToAuthApp();
    }

    return (
      <Content maxWidth={theme.size.maxWidthXS}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>
          <form onKeyPress={this.onKeyPress} className={classes.form} {...other} />
        </Paper>
      </Content>
    );
  }
}

AuthForm.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onEnter: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.currentUser.authenticated,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goToAuthApp: () => {
      dispatch(appActions.goToAuthApp())
    },
  };
};

export default withStyles(styles)(
  withTheme()(
    connect(mapStateToProps, mapDispatchToProps)(AuthForm)
  )
);
