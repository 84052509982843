import { withTheme } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ScrollToTopFab from '../../Action/ScrollToTopFab';

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isScrollable: false,
        };
    }

    render() {
        const { theme, width, maxWidth, fullWidth, scrollable, dispatch, ...other } = this.props;

        var style = {};
        if (fullWidth) {
            style.maxWidth = '100%';
            style.margin = 0;
            style.padding = 0;

        } else {
            if (maxWidth) {
                style.maxWidth = maxWidth;
                style.margin = 'auto';
            }
            style.padding = `${theme.spacing.unit * 2}px`;
        }

        var size = '56px';
        if (isWidthDown('sm', width)) {
            size = '40px'
        }

        var scrollFabStyle = {
            position: 'fixed',
            bottom: theme.spacing.unit * 2,
            right: theme.spacing.unit * 2,
            zIndex: 99, /* Make sure it does not overlap */
            width: `${size}`,
            height: `${size}`,
        };

        return (
            <div>
                {scrollable &&
                    <ScrollToTopFab style={scrollFabStyle} />
                }
                <div style={style} {...other} />
            </div>
        );
    }
};

Content.defaultProps = {
    maxWidth: null,
    fullWidth: false,
    scrollable: true,
};

Content.propTypes = {
    theme: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    maxWidth: PropTypes.string,
    fullWidth: PropTypes.bool,
    scrollable: PropTypes.bool,
};

export default withWidth()(
    withTheme()(Content)
);
