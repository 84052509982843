import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/AddShoppingCartRounded';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Button from '../../../components/Common/Action/Button';
import SimpleSelect from '../../../components/Common/Forms/SimpleSelect';
import Paper from '../../../components/Common/Layout/Paper';
import Slideshow from '../../../components/Common/Layout/Slideshow';
import TabSection from '../../../components/Common/Layout/TabSection';
import { actionCreators as cartActions } from './../../../store/reducers/cart';
import DescriptionTab from './descriptionTab';
import InformationsTab from './informationsTab';

const styles = theme => ({
    paper: {
        padding: `${theme.spacing.unit * 2}px`,
        [theme.breakpoints.up('sm')]: {
            maxWidth: theme.size.maxWidthM,
            margin: `auto`,
            padding: `${theme.spacing.unit * 4}px`,
        },
    },

    options: {
        padding: `${theme.spacing.unit}px !important`,
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing.unit * 3}px !important`,
        },
        '& #choice': {
            paddingBottom: `${theme.spacing.unit * 3}px`,
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('md')]: {
                maxWidth: 'fit-content',
            },
        },
        '& #price': {
            padding: `${theme.spacing.unit}px`,
            fontSize: '2rem',
            [theme.breakpoints.down('sm')]: {
                padding: `${theme.spacing.unit * 3}px 0 0 ${theme.spacing.unit}px`,
                fontSize: '1.5rem',
            }
        },
        '& #shopbutton': {
            margin: `${theme.spacing.unit * 2}px 0`,
            padding: `${theme.spacing.unit * 1.5}px`,
            [theme.breakpoints.down('sm')]: {
                width: `100%`,
            },
            '& svg': {
                marginRight: `${theme.spacing.unit}px`,
            },
        }
    },

    details: {
        paddingTop: `${theme.spacing.unit * 2}px`,
        [theme.breakpoints.up('md')]: {
            paddingTop: `${theme.spacing.unit * 5}px`,
        },
    }
});

class Product extends Component {

    constructor(props) {
        super(props);

        var selectedValues = {};
        props.product.variationsAttributes.forEach(att => {
            selectedValues[att] = 0;
        });
        this.state = {
            selectedVariation: null,
            selectedValues: selectedValues,
        };
    }

    componentDidMount() {
        this.selectVariation();
    }

    componentDidUpdate() {
        this.selectVariation();
    }

    selectVariation() {
        const { product, currency } = this.props;

        var selectedVariation = null;
        product.variations.forEach(variation => {
            if (variation.currency && variation.currency.toLowerCase() === currency) {
                var isSelectedVariation = true;
                for (let [key, value] of Object.entries(variation.attributes)) {
                    var selectedValue = this.state.selectedValues[key];
                    isSelectedVariation = isSelectedVariation &&
                        (value === product.variationsValues[key][selectedValue])
                }
                selectedVariation = isSelectedVariation ? variation : selectedVariation;
            }
        });
        if (JSON.stringify(this.state.selectedVariation) !== JSON.stringify(selectedVariation)) {
            this.setState({ selectedVariation: selectedVariation })
        }
    }

    handleChangeValues = event => {
        var selectedValues = Object.assign({},
            this.state.selectedValues,
            { [event.target.name]: event.target.value });
        this.setState({ selectedValues: selectedValues });
    }

    getVariationQuantity(variation) {
        const { t } = this.props

        if (!variation || (!variation.infinite && variation.quantity === null)) {
            return t('shop.unavailable');
        }

        if (variation.infinite) {
            return t('shop.in_stock');
        } else {
            if (variation.quantity > 0) {
                if (variation.quantity === 1) {
                    return t('shop.one_left');
                } else {
                    return variation.quantity + t('shop.x_left');
                }
            }
            return t('shop.out_of_stock');
        }
    }

    render() {
        const { t, i18n, classes, product, products, currency } = this.props;
        const { selectedVariation, selectedValues } = this.state;

        return (
            <Paper className={classes.paper}>
                <Grid container spacing={8}>
                    {/* IMAGES */}
                    <Grid item xs={12} sm={7} >
                        <Slideshow images={product.images} defaultImage={product.defaultImage} />
                    </Grid>

                    {/* OPTIONS */}
                    <Grid item xs={12} sm={5} className={classes.options}>
                        <Typography gutterBottom variant="h6" component="h3">
                            {product.title}
                        </Typography>

                        {product.variationsAttributes.length > 0 &&
                            <div id="choice">
                                {product.variationsAttributes.map(attribute => {
                                    return (
                                        <SimpleSelect key={attribute}
                                            label={t(attribute)}
                                            name={attribute}
                                            items={product.variationsValues[attribute].map((value, index) => ({ id: index, name: t(value) }))}
                                            value={selectedValues[attribute]}
                                            onChange={this.handleChangeValues}
                                            required />
                                    );
                                })}

                                <Typography>
                                    {this.getVariationQuantity(selectedVariation)}
                                </Typography>
                            </div>
                        }

                        <div id="pricing">
                            <Typography id="price">
                                {selectedVariation ?
                                    new Intl.NumberFormat(i18n.language, { style: 'currency', currency: currency })
                                        .format(selectedVariation.price)
                                    : <>&nbsp;</>}
                            </Typography>

                            <Button id="shopbutton"
                                disabled={!selectedVariation || selectedVariation.quantity <= 0}
                                onClick={() => this.props.addToCart(selectedVariation.id)}>
                                <AddIcon /> {t('action.addToCart')}
                            </Button>

                            <Typography variant="caption">
                                <Trans i18nKey="shop.delivery_info" />
                            </Typography>
                        </div>
                    </Grid>
                </Grid>

                {/* DETAILS */}
                <TabSection className={classes.details}
                    tabsHeader={[t('shop.description'), t('shop.additional_information')]}
                    tabContent={(selectedTab) => {
                        switch (selectedTab) {
                            case 1: return <InformationsTab product={product} />
                            default: return <DescriptionTab product={product} products={products} currency={currency} />
                        }
                    }}
                />
            </Paper>
        );
    }
}

Product.propTypes = {
    classes: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        currency: state.shop.currency,
        products: state.shop.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addToCart: (variationId) => {
            dispatch(cartActions.addProduct(variationId))
        },
    };
};

export default withStyles(styles)(
    withTranslation()(
        connect(mapStateToProps, mapDispatchToProps)(Product)
    )
);
