import { Tab, Tabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = theme => ({
    tabheader: {
        borderBottom: `${theme.palette.secondary.dark} solid 1px`,
        boxShadow: 'none',
    },
    selected: {
        fontWeight: 600,
    },
    tabcontent: {
        paddingTop: `${theme.spacing.unit * 2}px `,
    },
});

class TabSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: props.selectedTab ? props.selectedTab : 0,
        };
    }

    componentDidUpdate() {
        if (this.props.selectedTab !== null && this.props.selectedTab !== this.state.selectedTab) {
            this.setState({ selectedTab: this.props.selectedTab ? this.props.selectedTab : 0 })
        }
    }

    handleChangeTab = (event, value) => {
        this.setState({ selectedTab: value });
        if (this.props.handleChangeTab !== undefined) {
            this.props.handleChangeTab(value);
        }
    }

    render() {
        const { classes, className, tabsHeader, tabContent } = this.props;
        const { selectedTab } = this.state;

        return (
            <div className={className}>
                <Tabs value={selectedTab} onChange={this.handleChangeTab}
                    textColor="secondary" className={classes.tabheader}
                    TabIndicatorProps={{ style: { display: 'none' } }}
                    variant="scrollable" scrollButtons="auto">

                    {tabsHeader.map((label, index) =>
                        <Tab label={label} key={index}
                            classes={{ selected: classes.selected }} />
                    )}

                </Tabs>
                <div className={classes.tabcontent}>
                    {tabContent(selectedTab)}
                </div>
            </div>
        );
    }
}

TabSection.defaultProps = {
    selectedTab: null,
}
TabSection.propTypes = {
    classes: PropTypes.object.isRequired,
    tabsHeader: PropTypes.array.isRequired,
    tabContent: PropTypes.func.isRequired,
    handleChangeTab: PropTypes.func,
    selectedTab: PropTypes.number,
};

export default withStyles(styles)(TabSection);