import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import * as localStorage from './localStorage';
import apiErrorMiddleware from './middlewares/apiErrorMiddleware';
import applicationMiddleware from './middlewares/applicationMiddleware';
import cartMiddleware from './middlewares/cartMiddleware';
import currentUserMiddleware from './middlewares/currentUserMiddleware';
import navigationMiddleware from './middlewares/navigationMiddleware';
import shopMiddleware from './middlewares/shopMiddleware';
import * as Application from './reducers/application';
import * as Cart from './reducers/cart';
import * as CurrentUser from './reducers/currentUser';
import * as Navigation from './reducers/navigation';
import * as Notification from './reducers/notification';
import * as Shop from './reducers/shop';
import * as sessionStorage from './sessionStorage';

export const history = createBrowserHistory();

export default function configureStore() {

    var persistedState = localStorage.loadState()
    persistedState = Object.assign({}, persistedState, sessionStorage.loadState());

    const appReducers = combineReducers({
        application: Application.reducer,
        currentUser: CurrentUser.reducer,
        navigation: Navigation.reducer,
        notification: Notification.reducer,
        shop: Shop.reducer,
        cart: Cart.reducer,
    });

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        connectRouter(history)(appReducers),
        persistedState,
        composeEnhancers(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                apiErrorMiddleware,
                applicationMiddleware,
                currentUserMiddleware,
                navigationMiddleware,
                shopMiddleware,
                cartMiddleware,
            )
        )
    );

    if (persistedState && persistedState.currentUser && persistedState.currentUser.accessToken) {
        // Check User account
        store.dispatch(CurrentUser.actionCreators.getAccount());
    }

    store.subscribe(() => {
        var sessionState = {};
        var localState = {
            cart: store.getState().cart
        };

        if (store.getState().currentUser.authenticated) {
            // Save "currentUser" in local OR session storage
            if (store.getState().currentUser.keepLogin) {
                localState.currentUser = store.getState().currentUser;
            } else {
                sessionState.currentUser = store.getState().currentUser;
            }
        }

        // Save local and session data
        localStorage.saveState(localState);
        sessionStorage.saveState(sessionState);
    });

    return store
}
