import { Grid, Typography } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Button from '../../../components/Common/Action/Button';
import OutlinedTextField from '../../../components/Common/Forms/OutlinedTextField';
import SimpleTextField from '../../../components/Common/Forms/SimpleTextField';
import Content from '../../../components/Common/Layout/Content';
import Paper from '../../../components/Common/Layout/Paper';
import { actionCreators as appActions } from '../../../store/reducers/application';

const styles = theme => ({
    form: {
        padding: `${theme.spacing.unit * 2}px`,
        [theme.breakpoints.down('sm')]: {
            padding: `${theme.spacing.unit * 2}px 0`,
        },
    },
});

class Contact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            nameError: false,
            email: this.props.email,
            emailError: false,
            message: '',
            messageError: false,
        };
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onKeyPress = event => {
        if (event.key === 'Enter') {
            this.sendEmail();
            event.preventDefault();
        }
    };

    validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    sendEmail = () => {
        const name = this.state.name.trim();
        const email = this.state.email.trim();
        const message = this.state.message.trim();

        // validation
        this.setState({ nameError: name === '' });
        this.setState({ emailError: (email === '' || !this.validateEmail(email)) });
        this.setState({ messageError: message === '' });
        if (name === '' || message === '' || (email !== '' && !this.validateEmail(email))) {
            return;
        }

        this.props.sendEmail({ name: name, email: email, message: message });
    }

    render() {
        const { t, classes, theme } = this.props;

        return (
            <Content maxWidth={theme.size.maxWidthS}>
                <Paper>
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="h5">{t('contact.title')}</Typography>
                        <Typography>{t('contact.subtitle')}</Typography>
                    </div>

                    <form className={classes.form} >
                        <Grid container spacing={16}>
                            <Grid item xs={12}>
                                <SimpleTextField fullWidth autoFocus
                                    label={t('contact.yourname')}
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    required
                                    error={this.state.nameError}
                                    onKeyPress={this.onKeyPress} />
                                <Typography variant="caption">{t('contact.yourname_info')}</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <SimpleTextField fullWidth
                                    label={t('contact.youremail')}
                                    id="email"
                                    name="email"
                                    autoComplete="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    required
                                    error={this.state.emailError}
                                    onKeyPress={this.onKeyPress} />
                                <Typography variant="caption">{t('contact.youremail_info')}</Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <OutlinedTextField fullWidth
                                    label={t('contact.yourmessage')}
                                    name="message"
                                    value={this.state.message}
                                    onChange={this.handleChange}
                                    required
                                    error={this.state.messageError}
                                    multiline rows={10} />
                            </Grid>
                        </Grid>
                        <Button onClick={this.sendEmail}>{t('contact.send_button')}</Button>
                    </form>
                </Paper>
            </Content>
        );
    }
}

Contact.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        name: state.currentUser.firstname,
        email: state.currentUser.email
    };
};

const mapDispatchToProps = dispatch => {
    return {
        sendEmail: (contact) => {
            dispatch(appActions.sendContactEmail(contact))
        },
    };
};

export default withStyles(styles)(
    withTheme()(
        connect(mapStateToProps, mapDispatchToProps)(
            withTranslation()(Contact)
        )
    )
);
