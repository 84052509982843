import { Avatar, GridList, GridListTile, ListSubheader, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import InstagramIcon from '../../images/social/instagram-white.svg';
import NewTabLink from '../Common/Link/NewTabLink';

const axios = require('axios');


const styles = theme => ({
    root: {
        margin: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px`,
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 2}px`,
        },
        [theme.breakpoints.down('xs')]: {
            margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
        },
    },

    title: {
        textAlign: 'center',
        marginBottom: `${theme.spacing.unit * 1.5}px `,
    },

    gridList: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: theme.size.maxWidthM,
        margin: 'auto !important',
    },

    subheaderLink: {
        '& #instavatar': {
            display: 'none',
        },
        '&:hover': {
            textDecoration: 'none',
            '& #instavatar': {
                display: 'initial',
                backgroundColor: theme.palette.secondary.main,
            },
            '& #profavatar': {
                display: 'none',
            },
        },
    },
    subheader: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        margin: 10,
        width: 50,
        height: 50,
        transition: 'all .2s',
    },

    image: {
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',

        width: '100%',
        paddingTop: '100%',

        transition: 'all .2s',
        opacity: 1,
        '&:hover': {
            opacity: 0.8,
            transform: 'scale(1.02)',
        },
    },
});

class InstagramFeed extends Component {

    constructor(props) {
        super(props);

        this.state = {
            nbCol: 3,
            maxImages: 9,

            profile_picture: '',
            images: [],
        };
    }

    componentDidMount() {
        this.checkWidth();
        this.getFeed();
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.width !== prevProps.width) {
            this.checkWidth();
        }
        if (this.state.maxImages !== prevState.maxImages) {
            this.getFeed();
        }
    }

    checkWidth() {
        var nbCol = 3;
        var maxImages = 9;
        if (isWidthDown('xs', this.props.width)) {
            nbCol = 2;
            maxImages = 6;
        }
        this.setState({ nbCol: nbCol, maxImages: maxImages });
    }

    async getFeed() {
        try {
            const response = await axios.get(
                "https://www.instagram.com/" + process.env.REACT_APP_INSTAGRAM_ID + "/?__a=1"
            );

            if (response.data && response.data.graphql) {
                var images = [];
                var array = response.data.graphql.user.edge_owner_to_timeline_media.edges;
                for (let index = 0; index < array.length && index < this.state.maxImages; index++) {
                    const element = array[index];
                    if (element && element.node && element.node.display_url && element.node.is_video === false) {
                        images.push({
                            display_url: element.node.display_url,
                            shortcode: element.node.shortcode,

                        })
                    }
                }
                this.setState({
                    profile_picture: response.data.graphql.user.profile_pic_url,
                    images: images
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    render() {
        const { t, classes } = this.props;
        const { profile_picture, images, nbCol } = this.state;

        const instagramID = process.env.REACT_APP_INSTAGRAM_ID;
        return (!instagramID ? <></> :
            <div className={classes.root}>
                <Typography variant="h4" component="h4" className={classes.title}>
                    {t('instagramFeed.follow')}
                </Typography>

                <GridList cols={nbCol} spacing={16} cellHeight='auto' className={classes.gridList}>
                    {/* PROFILE TITLE */}
                    <GridListTile key="Subheader" cols={nbCol}>
                        <NewTabLink className={classes.subheaderLink} href={"https://www.instagram.com/" + instagramID}>
                            <ListSubheader component="div" className={classes.subheader}>
                                <Avatar id="instavatar" src={InstagramIcon} alt={instagramID} className={classes.avatar} />
                                <Avatar id="profavatar" src={profile_picture} alt={instagramID} className={classes.avatar} />
                                <Typography variant="subtitle1" component="h4">
                                    {instagramID}
                                </Typography>
                            </ListSubheader>
                        </NewTabLink>
                    </GridListTile>

                    {/* IMAGES GRID */}
                    {images.map(img => (
                        <GridListTile key={img.shortcode}>
                            <NewTabLink href={"https://www.instagram.com/p/" + img.shortcode}>
                                <div style={{ backgroundImage: `url(${img.display_url})` }} className={classes.image} />
                            </NewTabLink>
                        </GridListTile>
                    ))}
                </GridList>
            </div>
        );
    }
}

InstagramFeed.propTypes = {
    classes: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
};

export default withStyles(styles)(
    withWidth()(
        withTranslation()(InstagramFeed)
    )
);
