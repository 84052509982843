import { FormControl, TextField } from '@material-ui/core/';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class SimpleTextField extends Component {
    render() {
        const { fullWidth, ...other } = this.props;

        return (
            <FormControl fullWidth={fullWidth}>
                <TextField margin="dense" {...other} />
            </FormControl>
        );
    }
}

SimpleTextField.defaultProps = {
    fullWidth: false
};

SimpleTextField.propTypes = {
    fullWidth: PropTypes.bool
};

export default SimpleTextField;
