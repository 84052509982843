import { Typography } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Content from '../../../components/Common/Layout/Content';
import Paper from '../../../components/Common/Layout/Paper';
import LinkMailto from '../../../components/Common/Link/LinkMailto';

const styles = theme => ({
    div: {
        margin: `${theme.spacing.unit * 3}px 0`,
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing.unit * 2}px 0`,
        },
    },
    title: {
        marginBottom: `${theme.spacing.unit / 2}px`,
    },
    paragraph: {
        textAlign: 'justify',
        marginTop: `${theme.spacing.unit * 2}px`,
    },
});

class PrivacyPolicy extends Component {
    render() {
        const { t, classes, theme } = this.props;

        return (
            <Content maxWidth={theme.size.maxWidthS}>
                <Paper>
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="h5">{t('privacy.title')}</Typography>
                        <Typography variant="caption">{t('privacy.subtitle')}</Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography component="p" className={classes.paragraph}>
                            Iridescent ("us", "we", or "our") operates https://smanager.lagunaworld.fr (the "Site"). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            Information Collection And Use
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your email address ("Personal Information").
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Personal Information collected may be stored and processed in France, you consent to any such transfer of information outside of your country.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Your information will never be shared with anyone outside Iridescent, except with your explicit consent.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            Log Data
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Like many site operators, we collect information that your browser sends whenever you visit our Site (“Log Data”).
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            In addition, we may use third party services such as Google Analytics that collect, monitor and analyze the usage of our service. Analytics data are anonymous.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            Communications
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            We may use your Personal Information to contact you with information related to the service.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            Cookies
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer’s hard drive.
                            Similarly, the localStorage stores data but has no expiration date, the data will be deleted when you log out.
                            </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            We do not use cookies but we use localStorage to keep you logged in when you use the service. We also store the data you consult to allow you offline consultation.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            However, if you do not accept localStorage, please stop using our Site.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            Security
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            Data Portability
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            As a Site User, you can send us a request to be send all of the data we have about you and your projects. Data will be delivered in JSON format.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            Changes To This Privacy Policy
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            This Privacy Policy is effective as of April 04, 2019 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.
                        </Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography variant="subtitle1" className={classes.title}>
                            Contact Us
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            If you have any questions about this Privacy Policy, please contact us by email at&nbsp;
                            <LinkMailto mailto="veronique.hoelt@gmail.com">veronique.hoelt@gmail.com</LinkMailto>
                            &nbsp;or via mail at the address provided below, and we will use reasonable efforts to promptly determine and remedy any problem.
                        </Typography>
                        <Typography component="p" className={classes.paragraph}>
                            <i>Véronique Hoelt / Iridescent<br />
                                113 route de Grenade<br />
                                31700 Blagnac - FRANCE</i>
                        </Typography>
                    </div>
                </Paper>
            </Content>
        );
    }
}

PrivacyPolicy.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(
    withTheme()(
        withTranslation()(PrivacyPolicy)
    )
);
