import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Link from '../../components/Common/Link';
import Canettes from '../../images/home/canettes.jpg';
import Trousses from '../../images/home/trousses-chats.jpg';
import Tshirts from '../../images/home/tshirts.jpg';

const styles = theme => ({
    root: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        }
    },
    firstDiv: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: '33.333333%',
            flexBasis: '33.333333%',
            display: 'flex',
        }
    },
    secondDiv: {
        [theme.breakpoints.up('md')]: {
            flexGrow: 0,
            maxWidth: '66.666667%',
            flexBasis: '66.666667%',
        },
    },
    spacing: {
        minHeight: `${theme.spacing.unit * 2}px`,
        minWidth: `${theme.spacing.unit * 2}px`,
        flexGrow: 0,
    },

    parentDiv: {
        overflow: 'hidden',
        flexGrow: 1,
    },

    firstImg: {
        '&::after': {
            backgroundImage: `url(${Tshirts})`,
        },
        height: '500px',
    },
    secondImg: {
        '&::after': {
            backgroundImage: `url(${Trousses})`,
        },
        height: '242px'
    },
    thirdImg: {
        '&::after': {
            backgroundImage: `url(${Canettes})`,
        },
        height: '242px'
    },
    img: {
        '&::after': {
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
        },
        [theme.breakpoints.down('sm')]: {
            height: '250px !important',
        },
    },

    imgContent: {
        display: 'flex',
        alignItems: 'flex-end',
        position: 'relative',
        margin: 0,

        textDecoration: 'none !important', //remove underline on hover
        textAlign: 'center',
        '& #text': {
            position: 'relative',
            margin: 0,
            zIndex: 1,
            width: '100%',
            color: 'white',
            background: 'rgba(0, 0, 0, 0.65)',
            padding: '0.5em',
            fontWeight: 500,
        },
    },

    zoomIn: {
        '&::after': {
            content: "''",
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            transformOrigin: 'center',
            transition: 'all .2s',
            opacity: 1,
        },
        '&:hover, &:focus, &:focus-within': {
            '&::after': {
                opacity: 0.8,
                transform: 'scale(1.02)',
            },
        },
    }
});

class ShopLinks extends Component {

    render() {
        const { classes } = this.props;
        const { t } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.firstDiv}>
                    <div className={classes.parentDiv}>
                        <Link href="/shop?search=t-shirt" className={classNames(classes.firstImg, classes.img, classes.imgContent, classes.zoomIn)}>
                            <Typography id='text' variant="h6">{t('home.shoplink_1')}</Typography>
                        </Link>
                    </div>
                    <div className={classes.spacing} />
                </div>
                <div className={classes.secondDiv}>
                    <div className={classes.parentDiv}>
                        <Link href="/shop?search=neko" className={classNames(classes.secondImg, classes.img, classes.imgContent, classes.zoomIn)}>
                            <Typography id='text' variant="h6">{t('home.shoplink_2')}</Typography>
                        </Link>
                    </div>
                    <div className={classes.spacing} />
                    <div className={classes.parentDiv}>
                        <Link href="/shop?search=t-shirt" className={classNames(classes.thirdImg, classes.img, classes.imgContent, classes.zoomIn)}>
                            <Typography id='text' variant="h6">{t('home.shoplink_3')}</Typography>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(
    withTranslation()(ShopLinks)
);
