import { Button } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = theme => ({
    button: {
        color: theme.palette.button.dark,
        '&:hover': {
            color: theme.palette.secondary.dark,
            backgroundColor: 'inherit',
        }
    },
    alert: {
        color: red[600],
        '&:hover': {
            color: red[900],
            backgroundColor: 'inherit',
        }
    }
});

class ActionButton extends Component {
    render() {
        const { classes, className, alert, ...other } = this.props;
        return (
            <Button
                size="small"
                className={classNames(alert ? classes.alert : classes.button, className)}
                {...other} />
        );
    }
}

ActionButton.defaultProps = {
    alert: false,
}

ActionButton.propTypes = {
    classes: PropTypes.object.isRequired,
    alert: PropTypes.bool,
};

export default withStyles(styles)(ActionButton);
