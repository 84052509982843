import i18next from 'i18next';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Content from '../../components/Common/Layout/Content';
import { actionCreators as shopActions } from '../../store/reducers/shop';
import ProductsList from './list';
import Product from './Product';

class Shop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filteredProductsId: [],
            selectedProductId: null,
        };
    }

    componentDidMount() {
        this.props.getProducts();
        this.checkQueryString();

        this.refreshProducts.bind(this);
        i18next.on('languageChanged', (lng) => this.refreshProducts(lng));
    }
    componentWillUnmount() {
        i18next.off('languageChanged', this.refreshProducts());
    }

    componentDidUpdate(prevProps) {
        this.checkQueryString();
    }

    checkQueryString() {
        const { location, products } = this.props;
        const category = this.props.match.params.category;
        const slug = this.props.match.params.slug;

        // Check if is product detail page
        if (slug !== null && slug !== undefined && slug !== '') {
            // Get product from slug
            var product = products.find(product => product.slug && product.slug.toLowerCase() === slug.toLowerCase())
            if (product && this.state.selectedProductId !== product.id) {
                this.setState({ selectedProductId: product.id, filteredProductsId: [] });
            } else { /*  product not found */ }

        } else {
            // Get products list
            var list = Array.from(products);

            // Filter products list by category
            if (category !== null && category !== undefined && category !== '') {
                list = list.filter(product => product.category && product.category.toLowerCase() === category);
            }

            // Filter products list with search words
            const params = queryString.parse(location.search);
            var search = (params.search && params.search !== '') ? params.search.split(' ') : [];
            if (search.length > 0 && list.length > 0) {
                list = list.filter(product => {
                    var result = true;
                    search.forEach(word => {
                        result = result
                            && (product.title.toLowerCase().includes(word.toLowerCase())
                                || (product.slug && product.slug.toLowerCase().includes(word.toLowerCase()))
                                || (product.category && product.category.toLowerCase().includes(word.toLowerCase()))
                                || (product.legend && product.legend.toLowerCase().includes(word.toLowerCase()))
                                || (product.description && product.description.toLowerCase().includes(word.toLowerCase())));
                    });
                    return result;
                });
            }

            // Update filtered products list
            var filteredProductsId = list.map(product => product.id);
            if (JSON.stringify(this.state.filteredProductsId) !== JSON.stringify(filteredProductsId)) {
                this.setState({ filteredProductsId: filteredProductsId, selectedProductId: null });
            }
        }
    }

    refreshProducts(lng) {
        if (lng) {
            // Refresh products list with new language
            this.props.getProducts();
        }
    }

    render() {
        const { products } = this.props;
        const { selectedProductId, filteredProductsId } = this.state;

        var selectedProduct = selectedProductId ? products.find(p => p.id === selectedProductId) : null;

        return (
            <Content>
                {selectedProduct ?
                    <Product product={selectedProduct} />
                    :
                    <ProductsList products={products.filter(product => filteredProductsId.includes(product.id))} />
                }
            </Content>
        );

    }
}

Shop.propTypes = {
    location: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        products: state.shop.products,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getProducts: () => {
            dispatch(shopActions.getProducts())
        },
    };
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(Shop)
);
