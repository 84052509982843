/* Initial state */
const initialState = {
    loading: false,
    editionInProgress: false,
};

/* Actions */
export const actions = {
    // Loader Overlay visibility
    startLoadingType: 'START_LOADING',
    stopLoadingType: 'STOP_LOADING',

    // Edition forms visibility
    startEditionType: 'START_EDITION',
    stopEditionType: 'STOP_EDITION',

    // Navigation
    goToHomeType: 'GO_TO_HOME',
    goToAuthAppType: 'GO_TO_AUTH_APP',
    goToSignInType: 'GO_TO_SIGN_IN',
    goToSignUpType: 'GO_TO_SIGN_UP',
    goToAboutType: 'GO_TO_ABOUT',
    goToErrorNotFoundType: 'GO_TO_ERROR_NOT_FOUND',

    // Contact Email
    goToContactType: 'GO_TO_CONTACT',
    sendContactEmailInitType: 'SEND_CONTACT_EMAIL',
    sendContactEmailSuccessType: 'SEND_CONTACT_EMAIL_SUCCESS',
    sendContactEmailErrorType: 'SEND_CONTACT_EMAIL_ERROR',
};

export const actionCreators = {
    // Loader Overlay visibility
    startLoading: () => ({ type: actions.startLoadingType }),
    stopLoading: () => ({ type: actions.stopLoadingType }),

    // Edition forms visibility
    startEdition: () => ({ type: actions.startEditionType }),
    stopEdition: () => ({ type: actions.stopEditionType }),

    // Navigation
    goToHome: () => ({ type: actions.goToHomeType }),
    goToAuthApp: () => ({ type: actions.goToAuthAppType }),
    goToSignIn: () => ({ type: actions.goToSignInType }),
    goToSignUp: () => ({ type: actions.goToSignUpType }),
    goToAbout: () => ({ type: actions.goToAboutType }),
    goToErrorNotFound: () => ({ type: actions.goToErrorNotFoundType }),

    // Contact Email
    goToContact: () => ({ type: actions.goToContactType }),
    sendContactEmail: (contact) => ({ type: actions.sendContactEmailInitType, contact }),
}

/* Reducer */
export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case actions.startLoadingType:
            return Object.assign({}, state, { loading: true })
        case actions.stopLoadingType:
            return Object.assign({}, state, { loading: false })

        case actions.startEditionType:
            return Object.assign({}, state, { editionInProgress: true })
        case actions.stopEditionType:
            return Object.assign({}, state, { editionInProgress: false })

        default:
            if (((action.type).startsWith(`CREATE`) || (action.type).startsWith(`UPDATE`))
                && !(action.type).startsWith(`CREATE_SESSION`)
                && (action.type).endsWith(`SUCCESS`)) {
                return initialState

            } else if ((action.type).endsWith(`ERROR`) || (action.type).endsWith(`SUCCESS`)) {
                return Object.assign({}, state, { loading: false })

            } else if ((action.type).startsWith(`CREATE`) || (action.type).startsWith(`UPDATE`)
                || (action.type).startsWith(`DELETE`) || (action.type).startsWith(`GET`)) {
                return Object.assign({}, state, { loading: true })
            }
            return state;
    }
};
