export const loadState = () => {
    try {
        const serializedState = window.sessionStorage.getItem(process.env.REACT_APP_NAME);
        if (serializedState === null) {
            return undefined;
        }

        return JSON.parse(serializedState);
    }
    catch (err) {
        return undefined;
    }
}

export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        window.sessionStorage.setItem(process.env.REACT_APP_NAME, serializedState);
    } catch (err) {
        console.log('Error');
        console.log(err);
    }
}