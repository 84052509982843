import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ActionButton from '../../Action/ActionButton';

const styles = theme => ({
    dialogPaper: {
        [theme.breakpoints.up('md')]: {
            height: '90%',
            maxWidth: theme.size.maxWidthM,
            maxHeight: `calc(${theme.size.maxWidthM} / 4 * 3)`, // ratio 4:3
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: theme.size.maxWidthL,
            maxHeight: `calc(${theme.size.maxWidthL} / 4 * 3)`, // ratio 4:3
        },
    },
    image: {
        margin: `${theme.spacing.unit}px ${theme.spacing.unit}px 0`,
        width: `calc(100% - ${theme.spacing.unit * 2}px)`,
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
    },
});

class ImageZoomDialog extends Component {
    render() {
        const { t, classes, fullScreen,
            isOpen, image, onPrevious, onNext, onClose } = this.props;

        return (
            <Dialog
                open={isOpen} onClose={() => onClose()}
                fullScreen={fullScreen} fullWidth
                classes={{ paper: classes.dialogPaper }}>

                <DialogContent className={classes.image} style={{ backgroundImage: `url(${image})` }} />

                <DialogActions>
                    <ActionButton onClick={() => onPrevious()}>{t('common.previous')}</ActionButton>
                    <ActionButton onClick={() => onNext()}>{t('common.next')}</ActionButton>
                    <ActionButton onClick={() => onClose()}>{t('common.close')}</ActionButton>
                </DialogActions>
            </Dialog >
        );
    }
}

ImageZoomDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    isOpen: PropTypes.bool.isRequired,
    image: PropTypes.any.isRequired,
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withStyles(styles)(
    withMobileDialog()(
        withTranslation()(ImageZoomDialog)
    )
);