import { Fab, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import BeforeIcon from '@material-ui/icons/NavigateBeforeRounded';
import NextIcon from '@material-ui/icons/NavigateNextRounded';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Link from '../../components/Common/Link';
import NoteBook from '../../images/home/notebook.jpg';
import classNames from 'classnames';

const styles = theme => ({
    imageDiv: {
        backgroundImage: `url(${NoteBook})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',

        alignItems: 'center',
        display: 'flex',

        '& h4': {
            color: 'white',
            fontWeight: 600,
            textAlign: 'center',
            width: '100%',
        },
    },

    textDiv: {
        overflow: 'auto',
        minHeight: '212px',
        maxHeight: '212px',
        padding: `${theme.spacing.unit * 5}px`,
        [theme.breakpoints.down('sm')]: {
            minHeight: '150px',
            maxHeight: 'initial',
            padding: `${theme.spacing.unit * 2}px`,
        },
    },

    navigateIcons: {
        textAlign: 'center',
        padding: '0 !important',
    },

    fab: {
        margin: `${theme.spacing.unit * 2}px`,
        backgroundColor: theme.palette.button.dark,
        color: theme.palette.button.light,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },

    link: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            width: '100%',
            textAlign: 'center',
            fontSize: '1rem',
        },
    },
    fullHeight: {
        minHeight: '300px',
        [theme.breakpoints.down('sm')]: {
            minHeight: '150px',
        },
    }
});

class Reviews extends Component {

    constructor(props) {
        super(props);

        // TODO: Récupérer les avis d'une vraie source de données.
        var reviews = [];
        if (process.env.NODE_ENV === 'development') {
            reviews = [
                // {
                //     name: "Anne Onyme",
                //     rating: 4,
                //     lang: 'fr',
                //     text: "Ceci est un avis super pertinent et objectif : C'est vraiment géniale cette boutique !!! Tout est super trop cool de la mort qui tue ! J'adooooooooooooooooooorrrreeee :)"
                // },
                // {
                //     name: "John Smith",
                //     rating: 4,
                //     lang: '',
                //     text: "Les t-shirts c'est vraiment le TOP ! Mais j'ai surtout aimé mon Ready To Go, très pratique et bien fini"
                // },
                // {
                //     name: "Piña & Ocarina",
                //     rating: 5,
                //     lang: 'fr',
                //     text: "Miaouuuuuu !!!!!!"
                // },
                // {
                //     name: "Ludovic The Boss",
                //     rating: 5,
                //     lang: 'fr',
                //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                // },
            ];
        }

        this.state = {
            index: 0,
            reviews: reviews,
        };
    }

    before = () => {
        var index = this.state.index - 1;
        if (index >= 0) {
            this.setState({ index: index });
        } else {
            this.setState({ index: this.state.reviews.length });
        }
    }
    next = () => {
        var index = this.state.index + 1;
        if (index <= this.state.reviews.length) {
            this.setState({ index: index });
        } else {
            this.setState({ index: 0 });
        }
    }

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const { reviews, index } = this.state;

        return (
            <Grid container>
                <Grid item xs={12} sm={4} md={6} className={classNames(classes.imageDiv, classes.fullHeight)}>
                    <Typography variant="h4" component="h4">
                        {t('home.reviews')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8} md={6}>
                    {(reviews.length > 0) ?
                        <>
                            <div className={classes.textDiv}>
                                {reviews[index] ? <>
                                    <Typography variant="subtitle1" component="p">
                                        {reviews[index].text}
                                    </Typography>
                                    <Typography variant="caption">{reviews[index].name} {reviews[index].lang && `(${reviews[index].lang.toUpperCase()})`}</Typography>
                                    {/* {reviews[index].rating && <Rating value={reviews[index].rating} readOnly />} */}
                                </>
                                    :
                                    <Link href="/contact" className={classes.link}>
                                        <Trans i18nKey="home.leaveAReview" />
                                    </Link>
                                }
                            </div>

                            <div className={classes.navigateIcons}>
                                <Fab className={classes.fab} onClick={this.before}>
                                    <BeforeIcon />
                                </Fab>
                                <Fab className={classes.fab} onClick={this.next}>
                                    <NextIcon />
                                </Fab>
                            </div>
                        </>
                        :
                        <Link href="/contact" className={classNames(classes.link, classes.fullHeight)}>
                            <span>
                                <Trans i18nKey="home.giveFirstReview" />
                            </span>
                        </Link>}
                </Grid>
            </Grid>
        );
    }
}

Reviews.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(
    withTranslation()(Reviews)
);
