/* Initial state */
const initialState = {
    authenticated: false,
    accessToken: '',
    id: null,
    firstname: '',
    lastname: '',
    email: '',
    locale: '',
    keepLogin: false
};

/* Actions */
export const actions = {
    createAccountInitType: 'CREATE_ACCOUNT',
    createAccountSuccessType: 'CREATE_ACCOUNT_SUCCESS',
    createAccountErrorType: 'CREATE_ACCOUNT_ERROR',

    createSessionInitType: 'CREATE_SESSION',
    createSessionSuccessType: 'CREATE_SESSION_SUCCESS',
    createSessionErrorType: 'CREATE_SESSION_ERROR',

    deleteSessionInitType: 'DELETE_SESSION',
    deleteSessionSuccessType: 'DELETE_SESSION_SUCCESS',
    deleteSessionErrorType: 'DELETE_SESSION_ERROR',

    expireSessionInitType: 'EXPIRE_SESSION',
    resetCurrentUserInitType: 'RESET_CURRENT_USER',

    getAccountInitType: 'GET_ACCOUNT',
    getAccountSuccessType: 'GET_ACCOUNT_SUCCESS',
    getAccountErrorType: 'GET_ACCOUNT_ERROR',

    updateAccountInitType: 'UPDATE_ACCOUNT',
    updateAccountSuccessType: 'UPDATE_ACCOUNT_SUCCESS',
    updateAccountErrorType: 'UPDATE_ACCOUNT_ERROR',

    updateLocaleInitType: 'UPDATE_LOCALE',
    updateLocaleSuccessType: 'UPDATE_LOCALE_SUCCESS',
    updateLocaleErrorType: 'UPDATE_LOCALE_ERROR',

    updateEmailInitType: 'UPDATE_EMAIL',
    updateEmailSuccessType: 'UPDATE_EMAIL_SUCCESS',
    updateEmailErrorType: 'UPDATE_EMAIL_ERROR',

    updatePasswordInitType: 'UPDATE_PASSWORD',
    updatePasswordSuccessType: 'UPDATE_PASSWORD_SUCCESS',
    updatePasswordErrorType: 'UPDATE_PASSWORD_ERROR',

    resetPasswordInitType: 'RESET_PASSWORD',
    resetPasswordSuccessType: 'RESET_PASSWORD_SUCCESS',
    resetPasswordErrorType: 'RESET_PASSWORD_ERROR',

    newPasswordInitType: 'NEW_PASSWORD',
    newPasswordSuccessType: 'NEW_PASSWORD_SUCCESS',
    newPasswordErrorType: 'NEW_PASSWORD_ERROR',
};

/* Action Creators */
export const actionCreators = {
    createAccount: (firstname, lastname, email, password, keepLogin, locale) => ({ type: actions.createAccountInitType, firstname, lastname, email, password, keepLogin, locale }),
    createSession: (email, password, keepLogin) => ({ type: actions.createSessionInitType, email, password, keepLogin }),
    deleteSession: () => ({ type: actions.deleteSessionInitType }),
    expireSession: () => ({ type: actions.expireSessionInitType }),
    resetCurrentUser: () => ({ type: actions.resetCurrentUserInitType }),

    getAccount: () => ({ type: actions.getAccountInitType }),
    updateAccount: (firstname, lastname) => ({ type: actions.updateAccountInitType, firstname, lastname }),
    updateLocale: (locale) => ({ type: actions.updateLocaleInitType, locale }),
    updateEmail: (email, password) => ({ type: actions.updateEmailInitType, email, password }),
    updatePassword: (oldPassword, newPassword) => ({ type: actions.updatePasswordInitType, oldPassword, newPassword }),
    resetPassword: (email) => ({ type: actions.resetPasswordInitType, email }),
    newPassword: (email, token, newPassword) => ({ type: actions.newPasswordInitType, email, token, newPassword }),
};

/* Reducer */
export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case actions.createAccountInitType:
        case actions.createSessionInitType:
            return Object.assign({}, state, {
                keepLogin: action.keepLogin
            })

        case actions.createAccountSuccessType:
        case actions.createSessionSuccessType:
            return Object.assign({}, state, {
                authenticated: true,
                accessToken: action.data.token,
                id: action.data.user.id,
                firstname: action.data.user.firstname,
                lastname: action.data.user.lastname,
                email: action.data.user.email,
                locale: action.data.user.locale
            })

        case actions.getAccountSuccessType:
            return Object.assign({}, state, {
                authenticated: true,
                id: action.data.id,
                firstname: action.data.firstname,
                lastname: action.data.lastname,
                email: action.data.email,
                locale: action.data.locale
            })

        case actions.deleteSessionSuccessType:
        case actions.deleteSessionErrorType:
        case actions.resetCurrentUserInitType:
        case actions.getAccountErrorType:
            return initialState

        case actions.expireSessionInitType:
            return Object.assign({}, state, {
                authenticated: false,
                accessToken: '',
            })

        case actions.updateAccountSuccessType:
        case actions.updateEmailSuccessType:
            return Object.assign({}, state, {
                id: action.data.id,
                firstname: action.data.firstname,
                lastname: action.data.lastname,
                email: action.data.email,
                locale: action.data.user.locale
            })

        default:
            break;
    }
    return state;
};
