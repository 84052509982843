import { FormControlLabel, Tooltip, Typography } from '@material-ui/core';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Button from '../../../components/Common/Action/Button';
import Checkbox from '../../../components/Common/Forms/Checkbox';
import SimpleTextField from '../../../components/Common/Forms/SimpleTextField';
import AuthForm from '../../../components/Common/Layout/AuthForm';
import RouteLink from '../../../components/Common/Link/RouteLink';
import { actionCreators as appActions } from '../../../store/reducers/application';
import { actionCreators as cUserActions } from '../../../store/reducers/currentUser';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            keepLogin: false,
        };
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    signIn = () => {
        this.props.createSession(this.state.email, this.state.password, this.state.keepLogin);
    };

    render() {
        const { goToSignUp } = this.props;
        const { t } = this.props;

        return (
            <AuthForm onEnter={this.signIn}>
                <SimpleTextField fullWidth autoFocus
                    label={t('auth.common.email')}
                    id="email"
                    name="email"
                    autoComplete="email"
                    value={this.state.email}
                    onChange={this.handleChange} />

                <SimpleTextField fullWidth
                    label={t('auth.common.password')}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={this.state.password}
                    onChange={this.handleChange} />

                <RouteLink to="/forgot-password" variant="caption">{t('auth.signin.forgotPassword_link')}</RouteLink>

                <br /><br />
                <Tooltip title={t('auth.common.keep_session_info')}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.keepLogin}
                                onChange={() => this.setState({ keepLogin: !this.state.keepLogin })}
                                value={this.state.keepLogin.toString()}
                            />
                        }
                        label={t('auth.common.keep_session')}
                    />
                </Tooltip>
                <br />
                <Button fullWidth onClick={this.signIn}>{t('auth.signin.button')}</Button>
                <br /><br />
                <Typography variant="caption">{t('auth.signin.goToSignUp_text')}</Typography>
                <Button variant="outlined" fullWidth onClick={goToSignUp}>{t('auth.signin.goToSignUp_button')}</Button>
            </AuthForm>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        goToSignUp: () => {
            dispatch(appActions.goToSignUp())
        },
        createSession: (email, password, keepLogin) => {
            dispatch(cUserActions.createSession(email, password, keepLogin))
        },
    };
};

export default withTranslation()(
    connect(null, mapDispatchToProps)(Login)
);
