import MuiButton from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const styles = theme => ({
    contained: {
        backgroundColor: theme.palette.button.dark,
        color: theme.palette.button.light,
        transition: 'all .2s',
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
        },
    },
    outlined: {
        color: theme.palette.button.dark,
        borderColor: theme.palette.button.dark,
        transition: 'all .2s',
        '&:hover': {
            borderColor: theme.palette.secondary.dark,
            color: theme.palette.secondary.dark,
            backgroundColor: 'inherit',
        },
    },

    button: {
        margin: `${theme.spacing.unit}px`,
        [theme.breakpoints.down('xs')]: {
            margin: `${theme.spacing.unit / 2}px`,
            padding: `${theme.spacing.unit / 2}px ${theme.spacing.unit}px`,
        },
    },
    largeButton: {
        fontSize: theme.typography.pxToRem(16),
        padding: `${theme.spacing.unit * 2}px`,
        [theme.breakpoints.up('sm')]: {
            minWidth: '300px',
            marginTop: `${theme.spacing.unit * 3}px`,
            marginBottom: `${theme.spacing.unit * 3}px`,
            padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
        },
    },

    centeredButton: {
        display: 'block',
        margin: `${theme.spacing.unit}px auto`,
        [theme.breakpoints.down('xs')]: {
            margin: `${theme.spacing.unit / 2}px auto`,
        },
    },
    autoMargin: {
        display: 'block',
        margin: `auto`,
    },

    responsiveFullWidth: {
        [theme.breakpoints.down('xs')]: {
            width: `calc(100% - ${theme.spacing.unit}px)`,
        },
    }
});

class Button extends Component {
    render() {
        const { classes, className, variant, large, center, autoMargin, responsiveFullWidth, ...other } = this.props;
        return (
            <MuiButton variant={variant}
                className={classNames(
                    large ? classes.largeButton : classes.button,
                    autoMargin ? classes.autoMargin : (center ? classes.centeredButton : ''),
                    responsiveFullWidth ? classes.responsiveFullWidth : '',
                    classes[variant], className)}
                {...other} />
        );
    }
}

Button.defaultProps = {
    variant: "contained",
    large: false,
    center: false,
    autoMargin: false,
    responsiveFullWidth: false,
};

Button.propTypes = {
    classes: PropTypes.object.isRequired,
    variant: PropTypes.string,
    large: PropTypes.bool,
    center: PropTypes.bool,
    autoMargin: PropTypes.bool,
    responsiveFullWidth: PropTypes.bool,
};

export default withStyles(styles)(Button);
