/* Initial state */
const initialState = {
    isVisible: false,
    translationKey: '',
    type: 'info',
};

/* Actions */
export const actions = {
    dismiss: 'DISMISS',
    showInfo: 'SHOW_INFO',
    showSuccess: 'SHOW_SUCCESS',
    showWarning: 'SHOW_WARNING',
    showError: 'SHOW_ERROR',

};

/* Action Creators */
export const actionCreators = {
    dismiss: () => ({ type: actions.dismiss }),
    showInfo: (translationKey) => ({ type: actions.showInfo, translationKey }),
    showSuccess: (translationKey) => ({ type: actions.showSuccess, translationKey }),
    showWarning: (translationKey) => ({ type: actions.showWarning, translationKey }),
    showError: (translationKey) => ({ type: actions.showError, translationKey }),
};

/* Reducer */
export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === actions.dismiss || (action.type).startsWith(`GO_TO`) || (action.type).endsWith(`LOCATION_CHANGE`)) {
        return Object.assign({}, state, { isVisible: false })
    }

    switch (action.type) {
        case actions.showInfo:
            return {
                isVisible: true,
                translationKey: action.translationKey,
                type: 'info',
            }

        case actions.showSuccess:
            return {
                isVisible: true,
                translationKey: action.translationKey,
                type: 'success',
            }

        case actions.showWarning:
            return {
                isVisible: true,
                translationKey: action.translationKey,
                type: 'warning',
            }

        case actions.showError:
            return {
                isVisible: true,
                translationKey: action.translationKey,
                type: 'error',
            }
        default:
            break;
    }

    return state;
};
