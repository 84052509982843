/* Initial state */
const initialState = [];

/* Actions */
export const actions = {
    addProductInitType: 'ADD_TO_CART',
    addProductSuccessType: 'ADD_TO_CART_SUCCESS',
    addProductErrorType: 'ADD_TO_CART_ERROR',

    removeProductInitType: 'REMOVE_FROM_CART',
    removeProductSuccessType: 'REMOVE_FROM_CART_SUCCESS',
    removeProductErrorType: 'REMOVE_FROM_CART_ERROR',
};

/* Action Creators */
export const actionCreators = {
    addProduct: (variationId) => ({ type: actions.addProductInitType, variationId }),
    removeProduct: (variationId) => ({ type: actions.removeProductInitType, variationId }),
};

/* Reducer */
export const reducer = (state, action) => {
    state = state || initialState;

    var index = -1;
    var list = [];

    switch (action.type) {
        case actions.addProductSuccessType:
            list = Array.from(state);
            if (action.data.variationId !== null) {
                list.push(action.data.variationId);
            }
            return list;

        case actions.removeProductSuccessType:
            index = state.findIndex((p) => p.variationId === action.variationId);
            if (index > -1) {
                return (state.slice(0, index)).concat(state.slice(index + 1))
            }
            break;

        default:
            break;
    }

    return state;
};
