/* Initial state */
const initialState = {
};

/* Actions */
export const actions = {
    goToShopType: 'GO_TO_SHOP',
    searchProductType: 'SEARCH_PRODUCT',

    goToMyCartType: 'GO_TO_MY_CART',
    goToMyProfilType: 'GO_TO_MY_PROFIL',
    goToMyOrdersType: 'GO_TO_MY_ORDERS',
};

/* Action Creators */
export const actionCreators = {
    goToShop: () => ({ type: actions.goToShopType }),
    searchProduct: (search) => ({ type: actions.searchProductType, search: search }),

    goToMyCart: () => ({ type: actions.goToMyCartType }),
    goToMyProfil: () => ({ type: actions.goToMyProfilType }),
    goToMyOrders: () => ({ type: actions.goToMyOrdersType }),
};

/* Reducer */
export const reducer = (state, action) => {
    return state || initialState;
};
