import { AppBar, Button, Menu, MenuItem, Toolbar } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { actionCreators as appActions } from '../../../store/reducers/application';
import { actionCreators as cUserActions } from '../../../store/reducers/currentUser';
import { actionCreators as navActions } from '../../../store/reducers/navigation';
import RouteLink from '../../Common/Link/RouteLink';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        boxShadow: theme.menu.boxShadow,
        transition: 'all .2s',

        minHeight: theme.size.navbar,
        maxHeight: theme.size.navbar,

        [theme.breakpoints.down('sm')]: {
            minHeight: 'initial !important',
            maxHeight: 'initial !important',
            '& div': {
                display: 'block',
                flexGrow: 'initial',
                textAlign: 'center',
            },
        },

        '& a, button': {
            padding: `0 ${theme.spacing.unit * 1.5}px`,
            [theme.breakpoints.down('sm')]: {
                padding: `0 ${theme.spacing.unit}px`,
            },
            '&:hover, &:focus, &:focus-within': {
                fontWeight: 600,
                color: theme.palette.secondary.main,
                backgroundColor: 'initial',
            },
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        minHeight: 'inherit',
        maxWidth: theme.size.maxWidthXL,
        width: '100%',
        margin: 'auto',
    },
    logo: {
        flexGrow: 1,
        '& #logo': {
            transition: 'all .2s',
            maxHeight: `calc(${theme.size.navbar} - ${theme.spacing.unit * 3}px)`,
            [theme.breakpoints.down('sm')]: {
                maxWidth: `175px`,
            },
        },
    },
    option: {
        '& li:hover, & li:focus, & li:focus-within': {
            backgroundColor: theme.palette.action.hover,
        },
    },
});


class NavBar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null, // the element to which the menu will be attached 
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.resizeNavBar);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.resizeNavBar);
    }

    resizeNavBar = () => {
        const { theme } = this.props;

        if (isWidthUp('md', this.props.width)) {
            // When the user scrolls down 30px from the top of the document, resize the header
            if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
                document.getElementById("navbar").style.minHeight = theme.size.navbarSmall;
                document.getElementById("navbar").style.maxHeight = theme.size.navbarSmall;
                document.getElementById("logo").style.maxHeight = `calc(${theme.size.navbarSmall} - ${theme.spacing.unit}px)`;
            } else {
                document.getElementById("navbar").style.minHeight = theme.size.navbar;
                document.getElementById("navbar").style.maxHeight = theme.size.navbar;
                document.getElementById("logo").style.maxHeight = `calc(${theme.size.navbar} - ${theme.spacing.unit * 3}px)`;
            }
        }
    }

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    processGoToMyProfil = () => {
        this.handleClose();
        this.props.goToMyProfil();
    };
    processGoToMyOrders = () => {
        this.handleClose();
        this.props.goToMyOrders();
    };
    processSignOut = () => {
        this.handleClose();
        this.props.deleteSession();
    };

    render() {
        const { classes } = this.props;
        const { t } = this.props;
        const { anchorEl } = this.state;

        return (
            <AppBar position="static" className={classes.root} id="navbar">
                <Toolbar className={classes.toolbar}>
                    <div className={classes.logo}>
                        <RouteLink to="/">
                            <img id="logo" alt="logo" src="/images/logo.png" />
                        </RouteLink>
                    </div>

                    <div>
                        {/* <Button size="small" onClick={this.props.goToShop}>{t('navbar.shop')}</Button> */}
                        <Button size="small" onClick={this.props.goToMyCart}>{t('navbar.mycart')}</Button>
                        {this.props.isAuthenticated ?
                            <span>
                                <Button aria-controls="account-menu" aria-haspopup="true" onClick={this.handleMenu}>
                                    {t('navbar.myaccount')}
                                </Button>
                                <Menu className={classes.option}
                                    id="account-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleClose}
                                >
                                    <MenuItem onClick={this.processGoToMyProfil}>{t('navbar.myinfos')}</MenuItem>
                                    <MenuItem onClick={this.processGoToMyOrders}>{t('navbar.myorders')}</MenuItem>
                                    <MenuItem onClick={this.processSignOut}>{t('navbar.logout')}</MenuItem>
                                </Menu>
                            </span>
                            :
                            <Button size="small" onClick={this.props.goToSignIn}>{t('navbar.myaccount')}</Button>
                        }
                        <Button size="small" onClick={this.props.goToAbout}>{t('navbar.about')}</Button>
                        <Button size="small" onClick={this.props.goToContact}>{t('navbar.contact')}</Button>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}


NavBar.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.currentUser.authenticated,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        goToShop: () => {
            dispatch(navActions.goToShop())
        },
        goToMyCart: () => {
            dispatch(navActions.goToMyCart())
        },
        goToMyProfil: () => {
            dispatch(navActions.goToMyProfil())
        },
        goToMyOrders: () => {
            dispatch(navActions.goToMyOrders())
        },

        goToAbout: () => {
            dispatch(appActions.goToAbout())
        },
        goToContact: () => {
            dispatch(appActions.goToContact())
        },
        goToSignIn: () => {
            dispatch(appActions.goToSignIn())
        },
        deleteSession: () => {
            dispatch(cUserActions.deleteSession())
        },
    };
};

export default withStyles(styles)(
    withWidth()(
        withTheme()(
            withTranslation()(
                connect(mapStateToProps, mapDispatchToProps)(NavBar)
            )
        )
    )
);
