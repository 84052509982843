import { Typography } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Content from '../../../components/Common/Layout/Content';
import Paper from '../../../components/Common/Layout/Paper';

const styles = theme => ({
    div: {
        margin: `${theme.spacing.unit * 3}px 0`,
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing.unit * 2}px 0`,
        },
    },
});

class Payment extends Component {

    render() {
        const { t, classes, theme } = this.props;

        return (
            <Content maxWidth={theme.size.maxWidthS}>
                <Paper>
                    <div style={{ textAlign: 'center' }}>
                        <Typography variant="h5">{t('payment.title')}</Typography>
                    </div>

                    <div className={classes.div}>
                        <Typography>
                            {t('payment.content')}
                        </Typography>
                    </div>
                </Paper>
            </Content>
        );
    }
}

Payment.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(
    withTheme()(
        withTranslation()(Payment)
    )
);
