import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Button from '../../../components/Common/Action/Button';
import Paper from '../../../components/Common/Layout/Paper';
import { actionCreators as appActions } from '../../../store/reducers/application';

const styles = theme => ({
    div: {
        textAlign: 'center',
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            minWidth: theme.size.maxWidthXS,
        },
    },
    title: {
        textTransform: 'uppercase',
    }
})

class NotFound extends Component {
    render() {
        const { classes } = this.props;
        const { t } = this.props;

        return (
            <Paper fullHeight>
                <div className={classes.div}>
                    <Typography variant="subtitle1" className={classes.title}>
                        {t('notfound.title')}
                    </Typography>
                    <Typography component="p">
                        {t('notfound.subtitle')}
                    </Typography>
                    <br />
                    <Button onClick={this.props.goToHome}>{t('common.goToHome_button')}</Button>
                </div>
            </Paper>
        );
    }
}

NotFound.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
    return {
        goToHome: () => {
            dispatch(appActions.goToHome())
        },
    };
};

export default withStyles(styles)(
    withTranslation()(
        connect(null, mapDispatchToProps)(NotFound)
    )
);
